import React, { useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { BLUE_COLOR } from '../constants';

const TrainingGroupSelector = ({
  groupData,
  level = 0,
  selected,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState({});

  const handleClick = (name) => {
    if (selected.includes(name)) {
      onSelect(selected.filter((s) => s !== name));
    } else {
      onSelect([...selected, name]);
    }
  };

  const toggleExpand = (name) => {
    setExpanded({
      ...expanded,
      [name]: !expanded[name],
    });
  };

  const hasChildren = (item) => {
    return Object.keys(item).some(
      (key) => key !== 'owner' && key !== 'id' && key !== 'disabled',
    );
  };

  return (
    <View style={{ borderWidth: 1, borderColor: BLUE_COLOR, padding: 8 }}>
      {Object.keys(groupData)
        .filter((key) => key !== 'owner' && key !== 'id' && key !== 'disabled')
        .map((name) => (
          <View
            key={name}
            style={{
              marginBottom: 10,
              // borderWidth: 1,
              // borderColor: BLUE_COLOR,
              // padding: 8,
            }}
          >
            {groupData[name] && groupData[name].disabled !== '1' && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <TouchableOpacity
                  onPress={() => handleClick(groupData[name].id)}
                  style={{ marginLeft: 20 * level }}
                >
                  <Text
                    style={{
                      textDecorationLine: selected.includes(groupData[name].id)
                        ? 'underline'
                        : 'none',
                    }}
                  >
                    {name}
                  </Text>
                </TouchableOpacity>
                {hasChildren(groupData[name]) && (
                  <TouchableOpacity
                    onPress={() => toggleExpand(name)}
                    style={{ marginLeft: 20 }} // Add margin for more space
                  >
                    <FontAwesome
                      name={expanded[name] ? 'minus' : 'plus'}
                      size={16}
                      color="black"
                    />
                    {/* Smaller size */}
                  </TouchableOpacity>
                )}
              </View>
            )}
            {groupData[name] && expanded[name] && (
              <TrainingGroupSelector
                groupData={groupData[name]}
                level={level + 1}
                selected={selected}
                onSelect={onSelect}
              />
            )}
          </View>
        ))}
    </View>
  );
};

export default TrainingGroupSelector;
