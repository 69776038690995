import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Modal,
  Linking,
} from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import DateTimePicker from '../../../components/DateTimePickerWrapper';
import { PRIMARY_COLOR, BLUE_COLOR, PMIData, pmiNameLookup } from '../../../constants';
import useApi from '../../../hooks/useApi';
import keepConnectApi from '../../../api/keepConnectsApi';
import Button from '../../../components/Button';
import Dropdown1 from '../../../components/Dropdown';
import { useSettings } from '../../../components/SettingsContext';
import ProgressBarsComponent from '../../../components/ProgressBarsComponent';
import { FontAwesome } from '@expo/vector-icons';
import alert from '../../../components/alert';

export function PMIDefault({ route, pmiCertification }) {
  //const route = useRoute();
  const sendCertificationToServer = useApi(keepConnectApi.sendCertificationToServer);
  const deleteCertificationFromServer = useApi(keepConnectApi.deleteCertificationFromServer);
  const getLogsForReconciliation = useApi(keepConnectApi.getLogsForReconciliation);
  const addRecordLedger = useApi(keepConnectApi.addRecordLedger);
  const { settings, addTrainingGroup, removeTrainingGroup } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [certificationType, setCertificationType] = useState('PMI');
  const [pmiCertType, setPMICertType] = useState(route.params?.log?.certLevel2 || pmiCertification);
  const [renewalDate, setRenewalDate] = useState(new Date());
  const [continuingHours, setContinuingHours] = useState('');
  const [additionalData, setAdditionalData] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [dbIndex, setDbIndex] = useState(null);
  const [previousRenewalDate, setPreviousRenewalDate] = useState(new Date());
  const [initialLicenseDate, setInitialLicenseDate] = useState(new Date());
  const [previousRenewalRecords, setPreviousRenewalRecords] = useState([]);
  const [logs, setLogs] = useState(null);
  const [ledger, setLedger] = useState({});
  const [sortedLogs, setSortedLogs] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [splitHours, setSplitHours] = useState(null);

  const showCategoryModal = (log) => {
    setSelectedLog(log);
    setGroupModalVisible(true);
  };

  useEffect(() => {
    const { log } = route.params || {};
    if (log) {
      console.log('Log: ', log);
      setCertificationType(log.certification_type || '');
      setPMICertType(log.certLevel2 || pmiCertification || '');
      setRenewalDate(new Date(log.renewal_date + 'T00:00:00') || new Date());
      setInitialLicenseDate(new Date(log.initial_date + 'T00:00:00') || new Date());
      setContinuingHours(log.continuing_hours || '');
      setLicenseNumber(log.license_number || '');
      setDbIndex(log.id || null);
      setPreviousRenewalDate(new Date(log.previous_renewal_date + 'T00:00:00') || new Date());
      setAdditionalData(log.additional_data || '');
      let updatedLedger = { ...JSON.parse(log.ledger) };
      const categories = PMIData[pmiCertType].categories;

      // Add any missing categories with 0 total
      Object.keys(categories).forEach((category) => {
        if (!updatedLedger[category]) {
          updatedLedger[category] = { total: 0, ids: [] };
        }
      });

      // if (!updatedLedger.CarryOver) {
      //   updatedLedger.CarryOver = { total: 0, ids: [] };
      // }
      if (!updatedLedger.Unreconciled) {
        updatedLedger.Unreconciled = { total: 0, ids: [] };
      }

      setLedger(updatedLedger);
    }
  }, []);

  useEffect(() => {
    if (!logs || !ledger) return;
    processLedger({ ...ledger });
  }, [logs]);

  const processLedger = (ledgerCopy) => {
    const categoryProperties = PMIData[pmiCertType].categories;
    let sortedLogsTemp = {};

    // Initialize all categories in sortedLogsTemp, even if they are empty.
    Object.keys(ledger).forEach((category) => {
      if (category !== 'SumTotal') {
        sortedLogsTemp[category] = [];
        ledgerCopy[category].total = 0;
      }
    });

    let unreconciledTotal = 0; // Initialize a variable to keep track of the total sum for "Unreconciled"

    logs.forEach((log) => {
      let foundCategory = null;

      // Check if this log's id is already recorded in any ledger category
      for (const [category, { ids }] of Object.entries(ledgerCopy)) {
        if (category === 'SumTotal') continue;
        ids.forEach((item) => {
          if (item.id === log.id) {
            foundCategory = category;
            // If the item has a child object, create a new log
            let logHours = 0;
            if (item.child) {
              const newLog = { ...log, ...item.child };
              sortedLogsTemp[category].push(newLog);
              logHours = item.child.hours;
            } else {
              sortedLogsTemp[category].push(log);
              logHours = log.hours;
            }
            // Update the category total based on logHours
            ledgerCopy[foundCategory].total = parseInt(ledgerCopy[foundCategory].total, 10) + parseInt(logHours, 10);

            // If rollup is enabled, walk the parent path and sum on the parent

            if (categoryProperties[foundCategory]?.rollup) {
              const parentPath = categoryProperties[foundCategory]?.parentPath?.split('/'); //parentPath includes this entry too
              if (parentPath) {
                for (let parent of parentPath) {
                  if (parent && categoryProperties.hasOwnProperty(parent)) {
                    let max = categoryProperties[parent].max;

                    // Do not roll up to the parent if max is hit
                    if (max !== undefined && results[parent] >= max) {
                      break;
                    }

                    // Roll up to the parent
                    ledgerCopy[parent].total = parseInt(ledgerCopy[parent].total, 10) + parseInt(logHours, 10);
                  }
                }
              }
            }
          }
        });
        if (foundCategory) {
          break;
        }
      }

      // If not found in any category, put it into "Unreconciled" category
      if (!foundCategory) {
        sortedLogsTemp['Unreconciled'].push(log);
        unreconciledTotal = parseInt(unreconciledTotal, 10) + parseInt(log.hours, 10); // Assuming each log has an 'hours' field
      }
    });

    ledgerCopy.SumTotal = { total: 0 };

    let sumTotal = 0;
    Object.keys(ledgerCopy).forEach((category) => {
      if (!categoryProperties[category]?.parentPath) {
        sumTotal += ledgerCopy[category].total;
      }
    });

    ledgerCopy.SumTotal.total = sumTotal;
    // Add the total sum for "Unreconciled" logs to sortedLogsTemp
    sortedLogsTemp['Unreconciled'].total = parseInt(unreconciledTotal, 10);

    console.log('Processed Ledger: ', ledgerCopy);
    setSortedLogs(sortedLogsTemp);
    setLedger(ledgerCopy);
    //console.log("Sorted Logs: ", sortedLogsTemp);
  };

  const RenewalInterval = {
    ANNUAL: 'Annual',
    BIENNIAL: 'Biennial',
    TRIENNIAL: 'Triennial',
    EVEN_YEARS: 'EvenYears',
    ODD_YEARS: 'OddYears',
    // ... add more intervals as needed
  };

  function getNextExpirationDate(renewalDate, renewalInterval, renewalDay) {
    let newDate = new Date(renewalDate);

    // Update year based on renewalInterval
    switch (renewalInterval) {
      case RenewalInterval.ANNUAL:
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      case RenewalInterval.BIENNIAL:
        newDate.setFullYear(newDate.getFullYear() + 2);
        break;
      case RenewalInterval.TRIENNIAL:
        newDate.setFullYear(newDate.getFullYear() + 3);
        break;
      case RenewalInterval.EVEN_YEARS:
        newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1));
        break;
      case RenewalInterval.ODD_YEARS:
        newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 !== 0 ? 2 : 1));
        break;
      // ... handle other cases
      default:
        throw new Error('Invalid renewal interval');
    }

    if (renewalDay) {
      switch (renewalDay) {
        case 'birthMonth1':
          newDate.setMonth(birthMonth - 1);
          newDate.setDate(1);
          break;
        case 'birthMonth2':
          newDate.setMonth(birthMonth);
          newDate.setDate(0); // Last day of previous month
          break;
        case 'initialDay':
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            newDate.setDate(initialDate.getDate() - 1);
          }
          break;
        default:
          //console.log(newDate, renewalDay);
          const [month, day] = renewalDay.split('/');
          newDate.setDate(1); // Temporarily set to the first day of any month
          newDate.setMonth(parseInt(month, 10) - 1);
          newDate.setDate(parseInt(day, 10) - 1);
          //console.log("Default Day of Month", month, day, newDate);
          break;
      }
    }
    console.log('New Date: ', newDate);
    return newDate;
  }

  const navigation = useNavigation();

  const collectIds = (obj, startKey) => {
    let ids = [];

    const helper = (subObj) => {
      if (subObj && subObj.id) {
        ids.push(subObj.id);
      }
      for (const key in subObj) {
        if (typeof subObj[key] === 'object' && key !== 'owner') {
          helper(subObj[key]);
        }
      }
    };

    helper(obj[startKey]);

    return ids.join(',');
  };

  //creates an array of training groups to be added to the user's training groups:
  //[{newGroup: "PMP", groupOwner: "PMP"}, {newGroup: "PMP", groupOwner: "PE", parentPath: ["PE"]}
  //the add/remove training group functions expect this array (removal gets reversed to remove children first)
  const createTrainingGroups = (reverse = false) => {
    const trainingGroupsTemp = [{ newGroup: pmiCertType, groupOwner: pmiCertType }];

    // Find the categories for the specified state
    const certData = PMIData[pmiCertType];

    if (certData && certData.categories) {
      Object.keys(certData.categories).forEach((category) => {
        const additionalPath = certData.categories[category].parentPath
          ? certData.categories[category].parentPath.split('/').filter((item) => item !== '')
          : [];
        trainingGroupsTemp.push({
          newGroup: category,
          groupOwner: pmiCertType,
          parentPath: [pmiCertType].concat(additionalPath),
        });
      });
    }

    return reverse ? trainingGroupsTemp.reverse() : trainingGroupsTemp;
  };

  const sendDataToServer = async (ledgerToSend) => {
    if (!certificationType || (!pmiCertType && certificationType === 'PMI') || !licenseNumber) {
      alert('Error', 'All fields must be filled out.');
      return;
    }

    setIsLoading(true);

    addTrainingGroup(createTrainingGroups(false)); //create the array of groups to add
    const payload = {
      certification_type: certificationType,
      certLevel2: pmiCertType,
      initial_date: initialLicenseDate.toISOString().split('T')[0],
      previous_renewal_date: previousRenewalDate.toISOString().split('T')[0],
      renewal_date: renewalDate.toISOString().split('T')[0],
      continuing_hours: continuingHours,
      license_number: licenseNumber,
      ledger: JSON.stringify(ledgerToSend),
      trainingGroup: collectIds(settings.training_groups, pmiCertType),
      dbIndex,
    };

    const response = await sendCertificationToServer.request(payload);

    //console.log(response.data);
    setIsLoading(false);
    if (route?.params?.setRefreshNeeded) route.params.setRefreshNeeded(true);
    navigation.goBack();
  };

  const deleteDataFromServer = async () => {
    setIsLoading(true);
    const payload = {
      dbIndex,
    };
    const response = await deleteCertificationFromServer.request(payload);
    //console.log(response.data);
    removeTrainingGroup(createTrainingGroups(true)); //create the array of groups to remove (reverse = true)
    setIsLoading(false);
    if (route?.params?.setRefreshNeeded) route.params.setRefreshNeeded(true);
    navigation.goBack();
  };

  const onChangeDate = (event, selectedDate) => {
    if (selectedDate) {
      setRenewalDate(selectedDate);
    }
  };

  const onChangeInitialDate = (event, selectedDate) => {
    if (selectedDate) {
      setInitialLicenseDate(selectedDate);
      setRenewalDate(getNextExpirationDate(selectedDate, PMIData[pmiCertType].renewalInterval, PMIData[pmiCertType].renewalDay));
    }
  };

  const onChangePreviousDate = (event, selectedDate) => {
    if (selectedDate) {
      setPreviousRenewalDate(selectedDate);
      setRenewalDate(getNextExpirationDate(selectedDate, PMIData[pmiCertType].renewalInterval, PMIData[pmiCertType].renewalDay));
    }
  };

  const setLedgerAndSave = (ledgerToSave) => {
    setLedger(ledgerToSave);
    sendDataToServer(ledgerToSave);
  };

  const clearLedger = () => {
    let updatedLedger = {};
    const categories = PMIData[pmiCertType].categories;

    //console.log('imported renewal date: ', new Date(log.renewal_date + 'T00:00:00'));

    // Add any missing categories with 0 total
    Object.keys(categories).forEach((category) => {
      if (!updatedLedger[category]) {
        updatedLedger[category] = { total: 0, ids: [] };
      }
    });

    if (!updatedLedger.CarryOver) {
      updatedLedger.CarryOver = { total: 0, ids: [] };
    }
    if (!updatedLedger.Unreconciled) {
      updatedLedger.Unreconciled = { total: 0, ids: [] };
    }
    //console.log('Update Ledger:', JSON.stringify(updatedLedger));

    setLedger(updatedLedger);
    if (route.params?.setLedger) route.params.setLedger(updatedLedger);
    return updatedLedger;
  };

  const recordRenewal = async () => {
    const response = await addRecordLedger.request({
      certification_id: dbIndex,
      periodEndDate: renewalDate.toISOString().split('T')[0],
      ledger: JSON.stringify(ledger),
    });
    let nextRenewalDate = getNextExpirationDate(renewalDate, PMIData[pmiCertType].renewalInterval, PMIData[pmiCertType].renewalDay);
    setPreviousRenewalDate(renewalDate); //set the previous renewal to the current expiration date
    setRenewalDate(nextRenewalDate); //set the expiration date to the next expiration date

    setIsLoading(true);

    const payload = {
      certification_type: certificationType,
      certLevel2: pmiCertType,
      initial_date: initialLicenseDate.toISOString().split('T')[0],
      previous_renewal_date: renewalDate.toISOString().split('T')[0],
      renewal_date: nextRenewalDate.toISOString().split('T')[0],
      continuing_hours: continuingHours,
      license_number: licenseNumber,
      ledger: JSON.stringify(clearLedger()),
      trainingGroup: collectIds(settings.training_groups, pmiCertType),
      dbIndex,
    };

    const response2 = await sendCertificationToServer.request(payload);

    console.log(response2.data);
    setIsLoading(false);
    if (route?.params?.setRefreshNeeded) route.params.setRefreshNeeded(true);
    navigation.goBack();
  };

  const renewOnline = async () => {
    const url = PMIData[pmiCertType].link;

    if (url) {
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      } else {
        console.log(`Can't handle the URL: ${url}`);
      }
    } else {
      console.log('URL is not defined.');
    }
  };

  const reconcilePDH = async () => {
    const response = await getLogsForReconciliation.request({
      certification_id: dbIndex,
    });
    // Assume that response.data returns an object {logs: [], ledger: {}}
    console.log('Reconcile Pull:', response.data);
    setLogs(response.data);
    setIsLoading(false);
    navigation.navigate('ViewLedger', {
      log: route.params?.log,
      logsToLoad: logs,
      setRefreshNeeded: route.params?.setRefreshNeeded,
      setLedger: setLedgerAndSave,
    });
    //setCategoryModalVisible(true);
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" color={PRIMARY_COLOR} />
      ) : (
        <>
          <View style={styles.categoryBox}>
            <Text style={{ fontWeight: 'bold' }}>
              {pmiNameLookup[pmiCertType]} ({pmiCertType})
            </Text>
            <Text></Text>
          </View>

          <View style={styles.categoryBox}>
            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Initial License Date:</Text>
              <DateTimePicker value={initialLicenseDate} mode="date" display="default" onChange={onChangeInitialDate} />
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Date of Last Renewal:</Text>
              <DateTimePicker value={previousRenewalDate} mode="date" display="default" onChange={onChangePreviousDate} />
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Expiration Date:</Text>
              <DateTimePicker value={renewalDate} mode="date" display="default" onChange={onChangeDate} />
            </View>

            {/* <Text style={styles.leftAlignedLabel}>Continuing Hours:</Text>
          <TextInput
          placeholder="Continuing Hours"
          value={String(continuingHours)}
          editable={false}
          style={styles.input}
        /> */}
            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>License Number:</Text>
              <TextInput placeholder="License Number" value={licenseNumber} onChangeText={setLicenseNumber} style={styles.input} />
            </View>
          </View>

          {dbIndex && <ProgressBarsComponent data={route.params?.log} />}
          {dbIndex && (
            <>
              <View style={styles.iconRow}>
                <TouchableOpacity style={styles.iconBox} onPress={reconcilePDH}>
                  <FontAwesome name="link" size={24} color="black" />
                  <Text>Associate PDH's</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    navigation.navigate('ViewLedger', {
                      log: route.params?.log,
                      previousLedgers: true,
                    })
                  }
                >
                  <FontAwesome name="book" size={24} color="black" />
                  <Text>Previous{'\n'}Ledgers</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.iconBox} onPress={renewOnline}>
                  <FontAwesome name="credit-card" size={24} color="black" />
                  <Text>Renew{'\n'}Online</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.iconRow}>
                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    alert('Record Renewal', 'This will store the record and advance the renewal and expiration dates to the next interval.', [
                      {
                        text: 'Cancel',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                      },
                      {
                        text: 'Proceed',
                        onPress: () => {
                          recordRenewal();
                        },
                      },
                    ])
                  }
                >
                  <FontAwesome name="camera" size={24} color="black" />
                  <Text>Record{'\n'}Renewal</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.iconBox} onPress={() => sendDataToServer(ledger)}>
                  <FontAwesome name="save" size={24} color="black" />
                  <Text>Save{'\n'}Cert.</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    alert('Delete Certification', 'This will delete this certification from your account. Are you sure?', [
                      {
                        text: 'Cancel',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                      },
                      {
                        text: 'Proceed',
                        onPress: () => {
                          deleteDataFromServer();
                        },
                      },
                    ])
                  }
                >
                  <FontAwesome name="trash-o" size={24} color="black" />
                  <Text>Delete{'\n'}Cert.</Text>
                </TouchableOpacity>
              </View>
            </>
          )}

          {!dbIndex && (
            <>
              <View style={styles.iconRow}>
                <TouchableOpacity style={styles.iconBox} onPress={() => sendDataToServer(ledger)}>
                  <FontAwesome name="save" size={24} color="black" />
                  <Text>Save{'\n'}Cert.</Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  leftAlignedLabel: {},
  input: {
    flex: 0.8, // This will take 60% of the space
    borderWidth: 1,
    borderColor: 'gray',
    padding: 8,
    borderRadius: 5,
  },
  categoryBox: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#e0e0e0',
    width: '100%',
    padding: 5,
    backgroundColor: '#FFFFFF',
    marginBottom: 5,
    alignItems: 'center',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10, // Spacing between each row
    width: '100%',
    paddingHorizontal: 8, // Padding to ensure they don’t touch the edges
  },
  modalView: {
    width: 300,
    height: 600,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  logCard: {
    borderWidth: 2,
    borderRadius: 20,
    backgroundColor: BLUE_COLOR,
    borderColor: BLUE_COLOR,
    padding: 10,
    marginBottom: 10,
  },
  logTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  logDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logDetailItem: {
    backgroundColor: '#f0f0f0',
    padding: 5,
    borderRadius: 3,
  },
  trainingGroups: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  groupNameCard: {
    backgroundColor: '#f0f0f0',
    padding: 5,
    borderRadius: 3,
    marginRight: 5,
    marginBottom: 5,
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 10,
  },
  iconBox: {
    width: 80, // or adjust to your desired width
    height: 80, // ensure this matches width to keep it square
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: PRIMARY_COLOR,
    marginBottom: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // This is for Android
  },
});
