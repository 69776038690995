import React from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import { useSearchBar } from './useSearchBar';

export function SearchBar({ onSearch, clearable }) {
  const { handleClear, handleSearch, handleSearchTermChange, searchTerm } =
    useSearchBar(onSearch);

  return (
    <View style={styles.container}>
      <TextInput
        onChangeText={handleSearchTermChange}
        onSubmitEditing={handleSearch}
        placeholder="Search YouTube"
        returnKeyType="search"
        style={styles.input}
        value={searchTerm}
      />
      {clearable && searchTerm && (
        <TouchableOpacity onPress={handleClear}>
          <Icon name="close" size={20} color="gray" />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderWidth: 1,
    borderRadius: 30,
    color: 'black',
    flexDirection: 'row',
    height: 30,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  input: { flex: 1 },
});
