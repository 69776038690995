import { useQuery } from '@tanstack/react-query';

import { YOUTUBE_API_KEY } from '../../../constants';

export function useGetVideo(id) {
  return useQuery({
    queryFn: () =>
      fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${id}&key=${YOUTUBE_API_KEY}`).then((res) => res.json()),
    queryKey: ['getVideo', id],
    enabled: !!id && id !== '',
  });
}

// I've left this here as an alternative fetch hook
// incase you don't want to use react-query
// It's not used in the app, but it's here for reference
// You can replace the useGetVideo hook in src/screens/Video/useVideo.js
export function useAlternativeGetVideo(id) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id || id === '') return;

    setIsFetching(true);

    fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${id}&key=${YOUTUBE_API_KEY}`)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [id]);

  return { data, isFetching, error };
}
