import React, { createContext, useContext, useState, useEffect } from 'react';
import keepConnectApi from '../api/keepConnectsApi';
import useApi from '../hooks/useApi';

const SettingsContext = createContext();

export const useSettings = () => {
  return useContext(SettingsContext);
};

const parseTrainingGroups = (flatArray) => {
  const makeTree = (arr, parentId = null) => {
    const output = {};
    for (const item of arr) {
      if (item.parent_id === parentId) {
        const children = makeTree(arr, item.id);
        output[item.name] = { owner: item.owner.split(',').filter(Boolean), id: item.id, disabled: item.disabled };

        if (Object.keys(children).length > 0) {
          Object.assign(output[item.name], children);
        }
      }
    }
    return output;
  };
  //console.log({training_groups: makeTree(flatArray)});
  return { training_groups: makeTree(flatArray) };
};

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ training_groups: { All: { owner: ['app'] } } });
  const getSettingsFromServer = useApi(keepConnectApi.getSettingsFromServer);
  const getTrainingGroupsFromServerAndUserSettings = useApi(keepConnectApi.getTrainingGroupsFromServerAndUserSettings);
  const sendSettingsToServer = useApi(keepConnectApi.sendSettingsToServer);
  const updateServerTrainingGroups = useApi(keepConnectApi.updateServerTrainingGroups);

  const loadSettings = async () => {
    const response = await getTrainingGroupsFromServerAndUserSettings.request();
    //console.log('Load Settings: ', response.data);
    if (response.ok) {
      let settingsCopy = { ...parseTrainingGroups(response.data.training_groups) };
      settingsCopy.user_settings = response.data.user_settings;
      settingsCopy.license = response.data.license;
      setSettings(settingsCopy);
    }
    // else {
    //   console.log("Error loading settings. Using defaults.");
    //   const response2 = await addTrainingGroup([{ newGroup: "All", groupOwner: "app" }]);
    //   response.ok && response.data && setSettings(parseTrainingGroups(response.data));
    //   //setSettings({ training_groups: { 'All': { owner: ['app'] } } });
    // }
  };

  const modifyUserSettings = async (newUserSettings) => {
    let settingsCopy = { ...settings };
    settingsCopy.user_settings = newUserSettings;
    setSettings(settingsCopy);
    const response = await sendSettingsToServer.request({ settings: newUserSettings });

    //console.log('Modify User Settings Response', response.data);

    //await loadSettings();
  };

  const addTrainingGroup = async (groupOperations) => {
    let updatedGroups = JSON.parse(JSON.stringify(settings.training_groups));
    let additions = [];
    let ownerUpdates = [];

    for (const operation of groupOperations) {
      const { newGroup, groupOwner, parentPath = [], parentId } = operation;
      let parentNode = updatedGroups;
      let parentTemporaryId = null;
      let fetchedParentId = null;

      for (const group of parentPath) {
        if (parentNode[group]) {
          parentTemporaryId = parentNode[group].temporaryId;
          fetchedParentId = parentNode[group].id;
          parentNode = parentNode[group];
        } else {
          console.log('Invalid parent path');
          return;
        }
      }

      const temporaryId = `temp_${Math.random().toString(36).substr(2, 9)}`;

      if (parentNode[newGroup]) {
        if (parentNode[newGroup].disabled) {
          parentNode[newGroup].disabled = false;
        }

        if (!parentNode[newGroup].owner.includes(groupOwner)) {
          parentNode[newGroup].owner.push(groupOwner);
          ownerUpdates.push({
            id: parentNode[newGroup].id,
            newOwner: parentNode[newGroup].owner.join(','),
            disabled: false,
          });
        }
      } else {
        parentNode[newGroup] = { owner: [groupOwner], temporaryId, disabled: false };
        additions.push({
          temporaryId,
          parentTemporaryId,
          parentId: fetchedParentId,
          name: newGroup,
          owner: groupOwner,
          disabled: false,
        });
      }
    }

    const response = await updateServerTrainingGroups.request({ additions, ownerUpdates, removals: [] });

    //console.log('Addition Response', response.data);

    await loadSettings();
  };

  const removeTrainingGroup = async (groupOperations) => {
    let updatedGroups = JSON.parse(JSON.stringify(settings.training_groups));
    let ownerUpdates = [];

    //console.log('Initial Updated Groups:', updatedGroups); // Debug line

    for (const operation of groupOperations) {
      const { newGroup, groupOwner, parentPath = [] } = operation;
      let parentNode = updatedGroups;

      for (const group of parentPath) {
        if (parentNode[group]) {
          parentNode = parentNode[group];
        } else {
          console.log('Invalid parent path');
          return;
        }
      }

      if (parentNode[newGroup]) {
        if (parentNode[newGroup].owner.includes(groupOwner)) {
          parentNode[newGroup].owner = parentNode[newGroup].owner.filter((owner) => owner !== groupOwner);

          if (parentNode[newGroup].owner.length === 0) {
            parentNode[newGroup].disabled = true;
          }

          ownerUpdates.push({
            id: parentNode[newGroup].id,
            newOwner: parentNode[newGroup].owner.join(','),
            disabled: parentNode[newGroup].disabled,
          });
        }
      }
    }

    const response = await updateServerTrainingGroups.request({ additions: [], ownerUpdates, removals: [] });
    console.log('Removal Response', response.data);

    await loadSettings();
  };

  useEffect(() => {
    loadSettings();
  }, []);

  //console.log('Settings:', settings); // Debug line
  return (
    <SettingsContext.Provider value={{ settings, setSettings, modifyUserSettings, loadSettings, addTrainingGroup, removeTrainingGroup }}>
      {children}
    </SettingsContext.Provider>
  );
};
