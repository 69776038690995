import React from "react";
import { useFormikContext } from "formik";

import Button from "../Button";

function SubmitButton({ title, customAction = () => {} }) {
  const { handleSubmit } = useFormikContext();

  const handlePress = () => {
    customAction();
    handleSubmit();
  };

  return <Button title={title} onPress={handlePress} />;
}

export default SubmitButton;
