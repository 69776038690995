import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Text, ActivityIndicator, Modal, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import DismissKeyboard from '../../components/DismissKeyboard';
import { PRIMARY_COLOR } from '../../constants';
import { ResultCard } from './components/ResultCard/ResultCard';
import { ResultCardFileUpload } from './components/ResultCard/ResultCardFileUpload';
import DateTimePicker from '../../components/DateTimePickerWrapper';
import Dropdown1 from '../../components/Dropdown';

import Button from '../../components/Button';
import useAuth from '../../auth/useAuth';
import keepConnectApi from '../../api/keepConnectsApi';
import useApi from '../../hooks/useApi';
import { useSettings } from '../../components/SettingsContext';
import { Dropdown } from 'react-native-element-dropdown';

export function LogView() {
  const auth = useAuth();
  const getLogsFromServer = useApi(keepConnectApi.getLogsFromServer);
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshNeeded, setRefreshNeeded] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
  });
  const [sortBy, setSortBy] = useState('date');
  const [filterBy, setFilterBy] = useState('all');
  const { settings, setSettings } = useSettings();
  const [savedDateRange, setSavedDateRange] = useState(null);
  const [presetRange, setPresetRange] = useState('12M');
  const [sortType, setSortType] = useState('0');

  useEffect(() => {
    if (settings.dateRange) {
      setDateRange(settings.dateRange);
    }
    if (settings.presetRange) {
      setPresetRange(settings.presetRange);
    }
    if (refreshNeeded) {
      loadKeepConnects();
      setRefreshNeeded(false);
    }
  }, [refreshNeeded]);

  const loadKeepConnects = async () => {
    const response = await getLogsFromServer.request({
      startDate: dateRange.startDate.toISOString().split('T')[0], // Format date to 'YYYY-MM-DD'
      endDate: dateRange.endDate.toISOString().split('T')[0], // Format date to 'YYYY-MM-DD'
    });
    console.log(response.data);
    setLogData(response.data);
    setIsLoading(false);
  };

  const filteredLogs = () => {
    if (filterBy === 'all') {
      return logData;
    }
    return logData.filter((log) => log.training_group === filterBy);
  };

  const totalRecords = filteredLogs().length;
  const totalHours = filteredLogs().reduce((acc, log) => {
    if (log.log_type === 'video') {
      return acc + parseFloat(log.logVideoOnLength ? log.video_length || 0 : log.video_watched_hours || 0);
    }
    return acc + parseFloat(log.hours || 0);
  }, 0);

  useEffect(() => {
    let sortedLogs = [...logData];
    if (sortType === '0') {
      // Ascending order
      sortedLogs.sort((a, b) => new Date(a.training_date) - new Date(b.training_date)); // Assuming 'date' exists in your logData
    } else if (sortType === '1') {
      // Descending order
      sortedLogs.sort((a, b) => new Date(b.training_date) - new Date(a.training_date)); // Assuming 'date' exists in your logData
    }
    setLogData(sortedLogs);
  }, [sortType]);

  const calculateDateRange = (value) => {
    const endDate = new Date();
    let startDate = new Date();

    switch (value) {
      case 'YTD':
        startDate.setMonth(0, 1);
        break;
      case '12M':
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case '6M':
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case '3M':
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case '0': // All Time
        startDate.setFullYear(startDate.getFullYear() - 100);
        break;
      default: // Custom Range or null
        startDate = null;
        endDate = null;
        break;
    }
    console.log(startDate, endDate);
    setDateRange({ startDate, endDate });
  };

  useEffect(() => {
    if (presetRange) {
      calculateDateRange(presetRange);
    }
  }, [presetRange]);

  const [showModal, setShowModal] = useState({
    dateRange: false,
    sortBy: false,
    filterBy: false,
  });

  const toggleModal = (type) => {
    setShowModal({ ...showModal, [type]: !showModal[type] });
  };

  if (isLoading) {
    return <ActivityIndicator size="large" color={PRIMARY_COLOR} />;
  }

  return (
    <DismissKeyboard>
      <>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
          {/* Icons for triggering modals */}
          <TouchableOpacity onPress={() => toggleModal('dateRange')}>
            <Ionicons name="calendar-outline" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => toggleModal('sortBy')}>
            <Ionicons name="reorder-four" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => toggleModal('filterBy')}>
            <Ionicons name="filter" size={24} color="black" />
          </TouchableOpacity>
        </View>

        {/* Date Range Modal */}
        <Modal visible={showModal.dateRange} onRequestClose={() => toggleModal('dateRange')} animationType="slide">
          <View style={styles.modalContainer}>
            <Dropdown1
              labelText="Select Preset Range"
              defaultValueProp={presetRange}
              itemsArray={[
                { label: 'Year to Date', value: 'YTD' },
                { label: 'Last 12 Months', value: '12M' },
                { label: 'Last 6 Months', value: '6M' },
                { label: 'Last 3 Months', value: '3M' },
                { label: 'Custom Range', value: null },
                { label: 'All Time', value: '0' },
              ]}
              onChangeItemProp={(value) => {
                setPresetRange(value);
                // Calculate and set the date range here
              }}
            />
            {/* DateTimePicker components are displayed only when no preset range is selected */}
            {presetRange === null && (
              <>
                <View style={{ flexDirection: 'row', paddingVertical: '20' }}>
                  <Text>Start Date:</Text>
                  <DateTimePicker
                    value={dateRange.startDate || new Date()}
                    mode="date"
                    onChange={(event, date) => setDateRange({ ...dateRange, startDate: date })}
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text>End Date:</Text>
                  <DateTimePicker
                    value={dateRange.endDate || new Date()}
                    mode="date"
                    onChange={(event, date) => setDateRange({ ...dateRange, endDate: date })}
                  />
                </View>
              </>
            )}
            <Button
              title="Close"
              onPress={() => {
                // setSettings({
                //   ...settings,
                //   dateRange: dateRange,
                //   presetRange: presetRange
                // });
                setRefreshNeeded(true);
                toggleModal('dateRange');
              }}
            />
          </View>
        </Modal>

        {/* Sort By Modal */}
        <Modal visible={showModal.sortBy} onRequestClose={() => toggleModal('sortBy')} animationType="slide">
          <View style={styles.modalContainer}>
            <Dropdown1
              labelText="Select Preset Range"
              defaultValueProp={sortType}
              itemsArray={[
                { label: 'Ascending Date', value: '0' },
                { label: 'Descending Date', value: '1' },
              ]}
              onChangeItemProp={(value) => {
                setSortType(value);
                // Calculate and set the date range here
              }}
            />
            <Button title="Close" onPress={() => toggleModal('sortBy')} />
          </View>
        </Modal>

        {/* Filter By Modal */}
        <Modal visible={showModal.filterBy} onRequestClose={() => toggleModal('filterBy')} animationType="slide">
          <View style={styles.modalContainer}>
            <Dropdown1
              labelText="Filter By"
              defaultValueProp={filterBy}
              itemsArray={[{ label: 'All', value: 'all' }, ...Object.keys(settings.training_groups).map((group) => ({ label: group, value: group }))]}
              onChangeItemProp={(value) => setFilterBy(value)}
            />
            <Button title="Close" onPress={() => toggleModal('filterBy')} />
          </View>
        </Modal>

        <ScrollView>
          <View style={styles.container}>
            {filteredLogs().length === 0 ? (
              <Text style={styles.center}>No logs found.</Text>
            ) : (
              <>
                <View style={styles.summaryBox}>
                  <Text style={styles.summaryText}>Total Records: {totalRecords}</Text>
                  <Text style={styles.summaryText}>Total Hours: {totalHours.toFixed(2)}</Text>
                </View>
                {filteredLogs().map((log, index) =>
                  log.log_type === 'video' ? (
                    <ResultCard
                      key={index}
                      id={log.video_id} // Assuming 'id' exists in your logData
                      logType={log.log_type}
                      title={log.title}
                      trainingGroup={log.training_group}
                      timeWatched={log.video_watched_hours}
                      videoLength={log.video_length}
                      log={log}
                      setRefreshNeeded={setRefreshNeeded}
                    />
                  ) : (
                    <ResultCardFileUpload
                      key={index}
                      id={log.id} // Assuming 'id' exists in your logData
                      logType={log.log_type}
                      title={log.title}
                      trainingGroup={log.training_group}
                      hours={log.hours}
                      filename={log.file_name}
                      log={log}
                      setRefreshNeeded={setRefreshNeeded}
                    />
                  ),
                )}
              </>
            )}
          </View>
        </ScrollView>
      </>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  spacer: {
    height: 30,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryBox: {
    padding: 16,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    margin: 8,
    borderColor: 'yellow',
    borderWidth: 1, // You can adjust the border width here
  },
  summaryText: {
    fontWeight: 'bold',
  },
});
