import { useNavigation } from '@react-navigation/native';
import { decode } from 'he';
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

export function ResultCard({ channelName, id, timeAgo, thumbnail, title }) {
  const navigation = useNavigation();

  function handleResultClick() {
    navigation.navigate('Video', { id, title });
  }

  return (
    <TouchableOpacity style={styles.container} onPress={handleResultClick}>
      <Image style={styles.thumbnail} source={{ uri: thumbnail }} />
      <View style={styles.textContainer}>
        <Text style={styles.titleText}>{decode(title)}</Text>
        <Text style={styles.subText}>
          {decode(channelName)} • {timeAgo}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    color: 'black',
    marginVertical: 5,
    shadowColor: 'black',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
  },
  thumbnail: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: '100%',
    aspectRatio: 16 / 9,
  },
  detailsContainer: { padding: 10, flexDirection: 'row' },
  textContainer: { flexDirection: 'column', padding: 10 },
  titleText: { marginBottom: 5 },
  subText: { fontSize: 12, color: 'gray' },
});
