import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { pmiNameLookup } from '../../../../constants';
// import ProgressBar from 'react-native-progress/Bar';
import ProgressBarsComponent from '../../../../components/ProgressBarsComponent';

export function ResultCard({ id, setRefreshNeeded, log }) {
  const navigation = useNavigation();

  function handleResultClick() {
    // Navigate to appropriate details screen (if necessary)
    navigation.navigate('CertificationView', { id, log, setRefreshNeeded });
  }

  // Function to render card for PE certification type
  function renderPE() {
    return (
      <>
        <Text style={styles.titleText}>PE Certification: {log.certLevel2}</Text>
        <Text style={styles.subText}>License Number: {log.license_number}</Text>
        <Text style={styles.subText}>Expiration Date: {log.renewal_date}</Text>
        <ProgressBarsComponent data={log} />
      </>
    );
  }

  // Function to render card for PMP certification type
  function renderPMI() {
    return (
      <>
        <Text style={styles.titleText}>
          {pmiNameLookup[log.certLevel2]} ({log.certLevel2})
        </Text>
        <Text style={styles.subText}>License Number: {log.license_number}</Text>
        <Text style={styles.subText}>Expiration Date: {log.renewal_date}</Text>
        <ProgressBarsComponent data={log} />
      </>
    );
  }

  // Main card rendering logic
  return (
    <TouchableOpacity style={styles.container} onPress={handleResultClick}>
      <View style={styles.textContainer}>
        {log.certification_type === 'PE' && renderPE()}
        {log.certification_type === 'PMI' && renderPMI()}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 10,
    color: 'black',
    marginVertical: 5,
    shadowColor: 'black',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    borderWidth: 2,
    borderColor: '#ffff08',
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
  titleText: {
    fontSize: 16,
    marginBottom: 5,
  },
  subText: {
    fontSize: 12,
    color: 'gray',
  },
});
