import React from "react";
import {View} from "react-native";
import { useFormikContext } from "formik";

import TextInput from "../TextInput";
import PopHelp from '../PopHelp';
import ErrorMessage from "./ErrorMessage";

function AppFormField({ name, width, helpText, ...otherProps }) {
  const {
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    values,
  } = useFormikContext();

  return (
    <>
      <View style={{flexDirection:"row"}}>
      <View style={{flex:9}}>
      <TextInput
        onBlur={() => setFieldTouched(name)}
        onChangeText={(text) => setFieldValue(name, text)}
        value={values[name]}
        width={width}
        {...otherProps}
      />
      </View>
      {helpText ? <View style={{flex:1,justifyContent: 'center'}}>
      <PopHelp>{helpText}</PopHelp>
      </View> : <></>}
      </View>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default AppFormField;
