import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView, StyleSheet, View, Image, Text, Modal, FlatList, TextInput, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import DismissKeyboard from '../../components/DismissKeyboard';
import { PRIMARY_COLOR } from '../../constants';
import Button from '../../components/Button';
import { useSettings } from '../../components/SettingsContext';
import DateTimePicker from '../../components/DateTimePickerWrapper';
import { set } from 'date-fns';

export function Settings() {
  const { settings, modifyUserSettings, addTrainingGroup, removeTrainingGroup } = useSettings();
  const [modalVisible, setModalVisible] = useState(false);
  const [newGroup, setNewGroup] = useState('');
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
  });

  useEffect(() => {
    if (settings?.user_settings?.profile) {
      const loadedProfile = settings.user_settings.profile;

      // Ensure dateOfBirth is a Date object
      let dateOfBirth = loadedProfile.dateOfBirth;
      if (!(dateOfBirth instanceof Date)) {
        // If it's not a Date object, try to convert it to one
        dateOfBirth = new Date(dateOfBirth);
      }

      // Update the profile state
      setProfile({
        ...loadedProfile,
        dateOfBirth: dateOfBirth,
      });

      console.log('Profile loaded:', loadedProfile);
      console.log('Type of Date of Birth:', typeof dateOfBirth);
    }
  }, []);

  const handleAddGroup = async () => {
    if (newGroup !== '') {
      await addTrainingGroup(newGroup);
      setNewGroup('');
    }
  };

  const handleRemoveGroup = async (group) => {
    await removeTrainingGroup(group);
  };

  const handleSaveProfile = () => {
    console.log('Profile saved:', profile);
    // Create a copy of the settings object and update its user_settings property
    const updatedSettings = {
      ...settings,
      user_settings: {
        ...settings.user_settings,
        profile: profile,
      },
    };
    modifyUserSettings(updatedSettings.user_settings);
    setProfileModalVisible(false);
  };

  const onChangeDate = (event, selectedDate) => {
    if (selectedDate) {
      //console.log('Selected Date:', selectedDate);
      setProfile({ ...profile, dateOfBirth: selectedDate });
    }
  };

  return (
    <DismissKeyboard onPress={Keyboard.dismiss}>
      <ScrollView>
        <View style={styles.container}>
          <Image style={styles.logo} source={require('../../../assets/KeepConnectlogoblankback.png')} />
          {/* <Button title="Manage Training Groups" onPress={() => setModalVisible(true)} /> */}
          <Button title="Manage User Profile" onPress={() => setProfileModalVisible(true)} />
        </View>

        {/* Modal to manage training groups */}
        <Modal animationType="slide" transparent={true} visible={modalVisible}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.bold}>Manage Training Groups</Text>
              <View style={styles.listContainer}>
                <FlatList
                  data={settings.training_groups}
                  renderItem={({ item }) => (
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Text>{item}</Text>
                      <TouchableOpacity onPress={() => handleRemoveGroup(item)}>
                        <AntDesign name="delete" size={24} color="black" />
                      </TouchableOpacity>
                    </View>
                  )}
                  keyExtractor={(item) => item}
                />
              </View>
              <Text>Enter New Training Group:</Text>
              <TextInput style={{ borderWidth: 1, width: 200, margin: 10 }} placeholder="New Group" value={newGroup} onChangeText={setNewGroup} />

              <Button title="Add" onPress={handleAddGroup} />
              <Button title="Close" onPress={() => setModalVisible(false)} />
            </View>
          </View>
        </Modal>

        {/* New Modal to manage user profile */}
        <Modal animationType="slide" transparent={true} visible={profileModalVisible}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={styles.bold}>Manage User Profile</Text>
              <Text>First Name:</Text>
              <TextInput
                style={{ borderWidth: 1, width: 200, margin: 10 }}
                placeholder="First Name"
                value={profile.firstName}
                onChangeText={(text) => setProfile({ ...profile, firstName: text })}
              />
              <Text>Last Name:</Text>
              <TextInput
                style={{ borderWidth: 1, width: 200, margin: 10 }}
                placeholder="Last Name"
                value={profile.lastName}
                onChangeText={(text) => setProfile({ ...profile, lastName: text })}
              />
              <Text>Date of Birth:</Text>
              <DateTimePicker value={profile.dateOfBirth} mode="date" display="default" onChange={onChangeDate} />
              <Button title="Save" onPress={handleSaveProfile} />
              <Button title="Close" onPress={() => setProfileModalVisible(false)} />
            </View>
          </View>
        </Modal>
      </ScrollView>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  spacer: {
    height: 30,
  },
  logo: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  listContainer: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    width: 250,
    marginBottom: 15,
  },
});
