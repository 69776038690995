import React from "react";
import Constants from "expo-constants";
import { StyleSheet, SafeAreaView, View, ScrollView, Image } from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

function Screen({ children, style }) {
  return (
    <SafeAreaView style={[styles.screen, style]}>
      <KeyboardAwareScrollView> 
      <View style={[styles.view, style]}>{children}</View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  view: {
    flex: 1,
    minWidth: 300,
    top: 20,
    padding: 20,
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    alignItems: "center",
    width: "100%",
},
logo: {
  width: 200,
  height: 200,
  resizeMode: 'contain',
  alignItems: "center",
},
});

export default Screen;
