import React, { useState } from 'react';
import { TouchableOpacity, Text, View, Modal } from 'react-native';
import { PRIMARY_COLOR } from '../constants';
import Button from './Button';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Utility function to get month name from month number
const getMonthName = (monthNumber) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return monthNames[monthNumber];
};

const DateTimePickerWrapper = ({ value, onChange, ...props }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleDateChange = (selectedDate) => {
    setShowPicker(false);
    onChange(null, selectedDate);
  };

  const displayDate = `${getMonthName(value.getMonth())} ${value.getDate()}, ${value.getFullYear()}`;

  return (
    <View>
      <TouchableOpacity onPress={() => setShowPicker(true)}>
        <Text>{displayDate}</Text>
      </TouchableOpacity>

      <Modal
        animationType="slide"
        transparent={true}
        visible={showPicker}
        onRequestClose={() => {
          setShowPicker(false);
        }}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <View
            style={{
              width: 400,
              height: 400,
              padding: 20,
              backgroundColor: 'white',
              borderRadius: 10,
              alignItems: 'center',
            }}
          >
            <Button title="Close" onPress={() => setShowPicker(false)} />
            <ReactDatePicker selected={value} onChange={handleDateChange} {...props} />
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default DateTimePickerWrapper;
