import { PEStateDefault } from './PEStates/PEStateDefault';

export const PEComponent = ({ route, newState }) => {
  const state = route.params?.log?.certLevel2 || newState;
  //console.log(newState);

  const renderStateSpecificComponent = () => {
    switch (state) {
      // case 'NY':
      //   return; // <NewYorkPEComponent params={params} />;
      // case 'CA':
      //   return; // <CaliforniaPEComponent params={params} />;
      default:
        return <PEStateDefault route={route} newState={state} />;
    }
  };

  return (
      renderStateSpecificComponent()
  );
};
