export default Object.freeze({
  LISTING_DETAILS: "ListingDetails",
  LISTING_EDIT: "ListingEdit",
  LOGIN: "Login",
  MESSAGES: "Messages",
  REGISTER: "Register",
  DASHBOARD: "DashboardScreen",
  DASHBOARDCOMPREHENSIVE: "DashboardScreenComprehensive",
  OVERVIEW: "OverviewScreen",
  DEVICE: "DeviceScreen",
  APPSETTINGS: "AppSettingsScreen",
  MESSAGESETTINGS: "MessageSettingsScreen",
  PRESENTSETTINGS: "PresentSettingsScreen",
  CHANGESETTINGS: "ChangeSettingsScreen",
  CHANGESETTINGSPOWERPAL: "ChangeSettingsScreenPowerPal",
  TRIGGERRESET: "TriggerResetScreen",
  CLOUDNOTIFICATIONS: "CloudNotificationsScreen",
  ADDREMOVEKEEPCONNECTS: "AddRemoveKeepConnectsScreen",
  PIPELINES: "PipelinesScreen",
});
