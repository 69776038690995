import apiClient from './client';
import apiClient2 from './client2';

//const getLogsFromServer = () => apiClient.get('certifymeup/v1/get-logs', {timestamp: Date.now()});
const getSettingsFromServer = () => apiClient.get('certifymeup/v1/get-user-settings', { timestamp: Date.now() });
const getTrainingGroupsFromServerAndUserSettings = () =>
  apiClient.get('certifymeup/v1/get-training-groups-and-user-settings', { timestamp: Date.now() });
const getCertificationsFromServer = () => apiClient.get('certifymeup/v1/get-certifications', { timestamp: Date.now() });
const getLogsFromServer = (postData) => {
  console.log('Get Logs:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/get-logs', postData);
};
const getRecordLedger = (postData) => {
  console.log('getRecordLedger:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/get-record-ledger', postData);
};
const addRecordLedger = (postData) => {
  console.log('addRecordLedger:');
  //console.log(postData);
  return apiClient.post('certifymeup/v1/add-record-ledger', postData);
};
const deleteRecordLedger = (postData) => {
  console.log('deleteRecordLedger:');
  //console.log(postData);
  return apiClient.post('certifymeup/v1/delete-record-ledger', postData);
};
const getLogsForReconciliation = (postData) => {
  console.log('get-logs-for-reconciliation:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/get-logs-for-reconciliation', postData);
};
const getLedgerAndLogs = (postData) => {
  console.log('get-ledger-and-logs:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/get-ledger-and-logs', postData);
};
const getLogsAndLedgerExport = (postData) => {
  console.log('downloadLedger:');
  console.log(postData);
  return apiClient2.post('certifymeup/v1/get-logs-and-ledger-export', postData);
};
const getHistoricLedgers = (postData) => {
  console.log('get-historic-ledgers:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/get-historic-ledgers', postData);
};
const sendSettingsToServer = (postData) => {
  console.log('Settings Are:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/set-user-settings', postData);
};
const updateServerTrainingGroups = (postData) => {
  console.log('Training Groups:');
  console.log(postData);
  return apiClient.post('certifymeup/v1/update-training-groups', postData);
};
const sendVideoLogToServer = (postData) => {
  console.log('postData: ' + JSON.stringify(postData));
  return apiClient.post('certifymeup/v1/add-video-log', postData);
};
const sendFileToServer = (postData) => {
  console.log('postData: ' + postData);
  return apiClient.post('certifymeup/v1/add-file-log', postData);
};
const deleteFileFromServer = (postData) => {
  console.log('postData: ' + postData);
  return apiClient.post('certifymeup/v1/delete-file-log', postData);
};
const deleteVideoLogFromServer = (postData) => {
  console.log('postData: ' + postData);
  return apiClient.post('certifymeup/v1/delete-video-log', postData);
};
const sendCertificationToServer = (postData) => {
  console.log('postData: ' + postData);
  return apiClient.post('certifymeup/v1/add-certification', postData);
};
const deleteCertificationFromServer = (postData) => {
  console.log('postData: ' + postData);
  return apiClient.post('certifymeup/v1/delete-certification', postData);
};

export default {
  sendVideoLogToServer,
  getLogsForReconciliation,
  getHistoricLedgers,
  getLedgerAndLogs,
  getLogsAndLedgerExport,
  getRecordLedger,
  addRecordLedger,
  deleteRecordLedger,
  deleteVideoLogFromServer,
  sendFileToServer,
  deleteFileFromServer,
  getLogsFromServer,
  getCertificationsFromServer,
  getSettingsFromServer,
  sendSettingsToServer,
  updateServerTrainingGroups,
  getTrainingGroupsFromServerAndUserSettings,
  sendCertificationToServer,
  deleteCertificationFromServer,
};
