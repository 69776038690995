import React from "react";
import { View, Text, Platform } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { Dropdown } from 'react-native-element-dropdown';
import colors from "../config/colors";

function Dropdown1({ labelText, defaultValueProp, itemsArray, onChangeItemProp, onTop, disabled = false }) {
  const isAndroid = Platform.OS === 'android';

  //console.log('itemsArray:', itemsArray)


  return (
    <>
      {labelText && (
        <><Text></Text><Text style={{ alignSelf: "center" }}>{labelText}</Text></>
      )}
        <View>
          <Dropdown
              data={itemsArray}
              value={defaultValueProp}
              labelField="label"
              valueField="value"
              searchField="label"
              onChange={(item) => onChangeItemProp(item.value)}
              style={{ backgroundColor: '#fafafa', height: 40, width: 200 }}
              disable={disabled}
          />
        </View>
    </>
  );
}

export default Dropdown1;
