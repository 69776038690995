import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';

import { SafeAreaView, StatusBar, StyleSheet, Platform } from 'react-native';

import { Search } from './screens/Search/Search';
import { Video } from './screens/Video/Video';
import { Overview } from './screens/Overview/OverviewScreen';
import { LogView } from './screens/LogView/LogViewScreen';
import { UploadFile } from './screens/UploadFile/UploadFileScreen';
import { Settings } from './screens/Settings/Settings';
import { Certifications } from './screens/Certifications/Certifications';
import { CertificationView } from './screens/CertificationView/CertificationView';
import { PRIMARY_COLOR } from './constants';
import ShareListener from './ShareListener';

import AppLoading from 'expo-app-loading';
import navigationTheme from './navigation/navigationTheme';
//import OfflineNotice from "./components/OfflineNotice";
import AuthNavigator from './navigation/AuthNavigator';
import AuthContext from './auth/context';
import { SettingsProvider } from './components/SettingsContext';
import authStorage from './auth/storage';
import { ViewLedger } from './screens/CertificationView/ViewLedger';

const Stack = createStackNavigator();

const styles = StyleSheet.create({
  container: { backgroundColor: '#909792', flex: 1 },
  header: { backgroundColor: '#909792' },
});

const stackScreenOptions = {
  headerStyle: styles.header,
  headerTintColor: 'white',
  headerTitleAlign: 'center',
  headerShadowVisible: false,
};

// This is for react-query, used in QueryClientProvider below, to cache data and handle loading states etc.
const client = new QueryClient();

export function Main() {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);

  const restoreUser = async () => {
    const user = await authStorage.getUser();
    if (user) setUser(user);
  };

  if (!isReady) return <AppLoading startAsync={restoreUser} onError={() => setIsReady(true)} onFinish={() => setIsReady(true)} />;

  return (
    <SafeAreaView style={styles.container}>
      <QueryClientProvider client={client}>
        <AuthContext.Provider value={{ user, setUser }}>
          <NavigationContainer>
            {Platform.OS !== 'web' && <ShareListener />}
            <StatusBar backgroundColor={PRIMARY_COLOR} barStyle="light-content" />
            {!user ? (
              <AuthNavigator />
            ) : (
              <SettingsProvider>
                <Stack.Navigator initialRouteName="Overview">
                  <Stack.Screen name="Search" component={Search} options={{ ...stackScreenOptions, title: 'Search' }} />
                  <Stack.Screen name="Overview" component={Overview} options={{ ...stackScreenOptions, title: 'Overview' }} />
                  <Stack.Screen name="LogView" component={LogView} options={{ ...stackScreenOptions, title: 'View Training Logs' }} />
                  <Stack.Screen name="UploadFile" component={UploadFile} options={{ ...stackScreenOptions, title: 'Upload File' }} />
                  <Stack.Screen name="Video" component={Video} options={{ ...stackScreenOptions, title: 'Video' }} />
                  <Stack.Screen name="Settings" component={Settings} options={{ ...stackScreenOptions, title: 'Settings' }} />
                  <Stack.Screen name="Certifications" component={Certifications} options={{ ...stackScreenOptions, title: 'Certifications' }} />
                  <Stack.Screen
                    name="CertificationView"
                    component={CertificationView}
                    options={{ ...stackScreenOptions, title: 'Certification View' }}
                  />
                  <Stack.Screen name="ViewLedger" component={ViewLedger} options={{ ...stackScreenOptions, title: 'View Ledger' }} />
                </Stack.Navigator>
              </SettingsProvider>
            )}
          </NavigationContainer>
        </AuthContext.Provider>
      </QueryClientProvider>
    </SafeAreaView>
  );
}
