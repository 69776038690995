import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Dropdown1 from '../../components/Dropdown'; // Make sure to import Dropdown correctly
import {PEComponent} from './PEComponent';
import {PMIComponent} from './PMIComponent';
import { useRoute } from '@react-navigation/native';

const US_STATES = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
  ];

const PMI_CERTIFICATIONS = [
    { label: 'PMP', value: 'PMP' },
    //{ label: 'CAPM', value: 'CAPM' },
  ];

export function CertificationView() {
  const route = useRoute();
  const typeFromParams = route.params?.log?.certification_type;
  
  const [showModal, setShowModal] = useState(!typeFromParams);
  const [type, setType] = useState(typeFromParams || null);
  const [certLevel2, setCertLevel2] = useState();

  const renderComponent = () => {
    switch (type) {
      case 'PE':
        return <PEComponent route={route} newState={certLevel2}/>;
      case 'PMI':
        return <PMIComponent route={route} pmiCertification={certLevel2}/>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (typeFromParams) {
      setType(typeFromParams);
    }
  }, [typeFromParams]);

  return (
    <>
        { !showModal && renderComponent() }

      <Modal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <Dropdown1
            labelText="Select Certification Type"
            defaultValueProp={type}
            itemsArray={[
              { label: 'PE', value: 'PE' },
              { label: 'PMI', value: 'PMI' },
            ]}
            onChangeItemProp={(value) => setType(value)}
          />

          {type === 'PE' && (
            <Dropdown1
              labelText="Select State"
              defaultValueProp={certLevel2}
              itemsArray={US_STATES}
              onChangeItemProp={(value) => setCertLevel2(value)}
            />
          )}

          {type === 'PMI' && (
            <Dropdown1
              labelText="Select Certification"
              defaultValueProp={certLevel2}
              itemsArray={PMI_CERTIFICATIONS}
              onChangeItemProp={(value) => setCertLevel2(value)}
            />
          )}

          <TouchableOpacity
            style={{ marginTop: 20 }}
            onPress={() => setShowModal(false)}
          >
            <Text>Create</Text>
          </TouchableOpacity>
        </View>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
