import * as SecureStore from "expo-secure-store";
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from "jwt-decode";
import { Platform } from "react-native";

const key = "authToken";

const storeToken = async (authToken) => {
  if (Platform.OS !== 'web'){
  try {
    await SecureStore.setItemAsync(key, authToken);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
      } else{
        try {
          await AsyncStorage.setItem(key, authToken);
        } catch (error) {
          console.log("Error storing the auth token (even from Async for Web)", error);
        }
      }
};

const getToken = async () => {
  if (Platform.OS !== 'web' ){
  try {
    return await SecureStore.getItemAsync(key);
  } catch (error) {
    console.log("Error getting the auth token", error);
  }
      } else{
        console.log("get from async");
        try {
          return await AsyncStorage.getItem(key);
        } catch (error) {
          console.log("Error getting the auth token (even from Async for Web)", error);
        }
      }
};

const getUser = async () => {
  const token = await getToken();
  return token ? jwtDecode(token) : null;
};

const removeToken = async () => {
  if (Platform.OS !== 'web' ){
  try {
    console.log("removing token");
    await SecureStore.deleteItemAsync(key);
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
  } else{
    try {
      console.log("removing token");
      await AsyncStorage.removeItem(key);
    } catch (error) {
      console.log("Error removing the auth token (even from Async for web)", error);
    }  
  }
};

export default { getToken, getUser, removeToken, storeToken };
