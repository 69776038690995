import React from 'react';
import { Platform, TouchableWithoutFeedback, Keyboard } from 'react-native';

const DismissKeyboard = ({ children }) => {
  if (Platform.OS === 'web') {
    return children;
  }

  return <TouchableWithoutFeedback onPress={Keyboard.dismiss}>{children}</TouchableWithoutFeedback>;
};

export default DismissKeyboard;
