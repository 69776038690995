import { PRIMARY_COLOR as ENV_PRIMARY_COLOR, YOUTUBE_API_KEY as ENV_YOUTUBE_API_KEY, MAX_RESULTS as ENV_MAX_RESULTS } from '@env';

export const PRIMARY_COLOR = '#ffff08';
export const BLUE_COLOR = '#4fb9d6';
export const YOUTUBE_API_KEY = 'AIzaSyCA2DD28x3O-QDioyB69D2s1SRrB7qZw0E';
export const MAX_RESULTS = '20';

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const PMIData = {
  PMP: {
    renewalInterval: 'Triennial',
    PDH: 60,
    rolloverPDH: 0,
    renewalDay: 'initialDay',
    categories: {
      Education: { min: 35, required: true, rollup: true },
      'Ways of Working': { min: 8, required: true, parentPath: '/Education', rollup: true },
      'Power Skills': { min: 8, required: true, parentPath: '/Education', rollup: true },
      'Business Acumen': { min: 8, required: true, parentPath: '/Education', rollup: true },
      'Giving Back': { max: 17, rollup: true },
      'Working As Professional': { max: 8, rollup: true },
    },
  },
};

export const pmiNameLookup = {
  PMP: 'Project Management Professional',
  CAPM: 'Certified Associate in Project Management',
  PMI_ACP: 'PMI Agile Certified Practitioner',
  PMI_RMP: 'PMI Risk Management Professional',
  PMI_SP: 'PMI Scheduling Professional',
  PMI_PBA: 'PMI Professional in Business Analysis',
  PgMP: 'Program Management Professional',
  PfMP: 'Portfolio Management Professional',
};

export const PEStateData = {
  AK: {
    renewalInterval: 'OddYears',
    PDH: 24,
    rolloverPDH: 12,
    renewalDay: '12/31',
    categories: {
      General: {
        min: 24,
        required: true,
        rollup: true,
      },
    },
  },
  AR: {
    renewalInterval: 'OddEvenLicense',
    PDH: 30,
    rolloverPDH: 30,
    renewalDay: '12/31',
    link: 'https://pelslicensing.arkansas.gov/',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  GA: {
    renewalInterval: 'Annual',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    'link:': 'https://secure.sos.state.ga.us/mylicense/Login.aspx?process=app',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  ID: {
    renewalInterval: 'EvenOddBirthYear',
    PDH: 30,
    rolloverPDH: 30,
    renewalDay: 'birthmonth2',
    link: 'https://www.accessidaho.org/ipels/',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  IL: {
    renewalInterval: 'OddYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '11/30',
    link: 'https://online-dfpr.micropact.com/',
    categories: {
      General: {
        min: 27,
        required: true,
        rollup: true,
      },
      'Sexual Harassment': {
        min: 1,
        required: true,
        rollup: true,
      },
      'Statutes and Rules': {
        min: 1,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  IN: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '7/31',
    link: 'https://mylicense.in.gov/eGov/index.html',
    categories: {
      General: {
        min: 28,
        required: true,
        rollup: true,
      },
      'Statutes and Rules': {
        min: 1,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  IA: {
    renewalInterval: 'OddEvenInitial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://ia-plb.my.site.com/IPLB_login',
    categories: {
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
      Group1: {
        rollup: true,
      },
      'Math and Basic Science': {
        max: 10,
        rollup: true,
        parentPath: '/Group1',
      },
      'Humanities and Social Science': {
        max: 5,
        rollup: true,
        parentPath: '/Group1',
      },
      'Engineering Courses': {
        max: 10,
        rollup: true,
        parentPath: '/Group1',
      },
      Group2: {
        rollup: true,
      },
      'Self Study': {
        max: 10,
        rollup: true,
      },
    },
  },
  KS: {
    renewalInterval: 'LastNameALEvenMZOddYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '4/30',
    link: 'https://licensing.ks.gov/EGOV_KBTP_213/',
    categories: {
      General: {
        min: 6,
        required: true,
        rollup: true,
      },
      'Health,Safety,Property,Welfare': {
        min: 24,
        required: true,
        rollup: true,
        '24HourMax': 10,
      },
    },
  },
  KY: {
    renewalInterval: 'LastNameAKOddLZEven',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '6/30',
    link: 'https://secure.kentucky.gov/formservices/KBOELS/LicenseRenewal',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  ME: {
    renewalInterval: 'OddYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://licensing.web.maine.gov/cgi-bin/online/licensing/menu_display.pl',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  MD: {
    renewalInterval: 'Biennial',
    PDH: 16,
    rolloverPDH: 8,
    renewalDay: 'initialDay2',
    link: 'https://www.dllr.state.md.us/cgi-bin/ElectronicLicensing/PE/PErenew1.cgi',
    categories: {
      General: {
        min: 15,
        required: true,
        rollup: true,
      },
      'MD Specifics': {
        min: 1,
        required: true,
        rollup: true,
        specialNote:
          'A minimum of 1 PDH in each biennial licensing term shall be earned from the participation in the completion of qualifying programs with content related to the following:\nThe awareness of ethical concerns and conflicts related to the practice of engineering;\nAn enhanced familiarity with the code of conduct for professional engineers;\nAn understanding of standards of practice or care related to the practice of engineering; or\nLaws and regulations applicable to the practice of engineering in Maryland.',
      },
    },
  },
  MI: {
    renewalInterval: 'Biennial',
    PDH: 30,
    rolloverPDH: 0,
    renewalDay: 'initialDay',
    link: 'https://www.michigan.gov/lara/bureau-list/bpl/miplus-assistant',
    categories: {
      General: {
        min: 28,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
    },
  },
  MN: {
    renewalInterval: 'EvenYears',
    PDH: 24,
    rolloverPDH: 12,
    renewalDay: '6/30',
    link: 'https://renewals.aelslagid.state.mn.us/',
    categories: {
      General: {
        min: 22,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
        noCarry: true,
      },
    },
  },
  MS: {
    renewalInterval: 'Annual',
    PDH: 15,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://www.peps.apps.its.ms.gov/home.aspx',
    categories: {
      General: {
        min: 14,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
        skipYear: true,
        noCarry: true,
      },
    },
  },
  MO: {
    renewalInterval: 'OddEvenInitial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://apps.dci.mo.gov/pr/renewal',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  NE: {
    renewalInterval: 'LastNameAKOddLZEven',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://www.nebraska.gov/EA/ea_renew.php',
    categories: {
      General: {
        min: 29,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  NH: {
    renewalInterval: 'OddEvenInitial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: 'birthmonth2',
    link: 'https://forms.nh.gov/license/Login.aspx',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  NJ: {
    renewalInterval: 'EvenYears',
    PDH: 24,
    rolloverPDH: 12,
    renewalDay: '4/30',
    link: 'https://www.nspe.org/user',
    categories: {
      General: {
        min: 22,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        max: 8,
        required: true,
        rollup: true,
      },
    },
  },
  NM: {
    renewalInterval: 'OddEvenLicense',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        max: 4,
        required: true,
        rollup: true,
        noCarry: true,
      },
    },
  },
  NC: {
    renewalInterval: 'Annual',
    PDH: 15,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://www.memberbase.com/ncbels-vs/login.asp',
    categories: {
      General: {
        min: 14,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  ND: {
    renewalInterval: 'OddEvenInitial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://ndpelsboard.org/online-personal-renewal/',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  NY: {
    renewalInterval: 'Triennial',
    PDH: 36,
    rolloverPDH: 0,
    renewalDay: 'birthMonth2',
    onlineAllowance: 18,
    link: 'https://eservices.nysed.gov/professions/renewal/login',
    categories: {
      General: {
        min: 35,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  OH: {
    renewalInterval: 'OddYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://elicense.ohio.gov/OH_HomePage',
    categories: {
      General: {
        min: 28,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
    },
  },
  OK: {
    renewalInterval: 'Biennial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: 'initialDay2',
    link: 'https://online.okpels.org/#/User/Loginwemail',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  OR: {
    renewalInterval: 'oregon',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '6/30 and 12/31 A-F or G-K odd years, L-R or S-Z even years',
    link: 'https://online.myosbeels.org/#/',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  PA: {
    renewalInterval: 'OddYears',
    PDH: 24,
    rolloverPDH: 12,
    renewalDay: '9/30',
    link: 'https://www.pals.pa.gov/#!/page/default',
    categories: {
      General: {
        min: 24,
        required: true,
        rollup: true,
      },
    },
  },
  SC: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '6/30',
    link: 'https://eservice.llr.sc.gov/SSO/Login/LoginPage?ReturnUrl=%2fOnlineRenewals%2f',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  SD: {
    renewalInterval: 'Biennial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: 'initialDay',
    link: 'https://apps.sd.gov/LD17BTP/login.aspx',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  TN: {
    renewalInterval: 'Biennial',
    PDH: 24,
    rolloverPDH: 12,
    renewalDay: 'initialDay',
    link: 'https://access.cloud.commerce.tn.gov/portal/public',
    categories: {
      General: {
        min: 24,
        required: true,
        rollup: true,
      },
    },
  },
  TX: {
    renewalInterval: 'Annual',
    PDH: 15,
    rolloverPDH: 1,
    renewalDay: 'texas',
    categories: {
      General: {
        min: 14,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
        noCarry: true,
      },
    },
  },
  UT: {
    renewalInterval: 'OddYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '3/31',
    link: 'https://secure.utah.gov/doplrenewal/doplrenewal',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  VA: {
    renewalInterval: 'Biennial',
    PDH: 16,
    rolloverPDH: 0,
    renewalDay: 'initialDay2',
    link: 'https://www.dporlicensehome.dpor.virginia.gov/datamart/askLicid.do',
    categories: {
      General: {
        min: 16,
        required: true,
        rollup: true,
      },
    },
  },
  WV: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://services.wvpebd.gov/pe/renewal/index.asp?reload=1',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  WI: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '7/31',
    onlineAllowance: 17,
    link: 'https://dsps.wi.gov/Documents/LicensE/LicensE_Renewal_Instructions.pdf',
    categories: {
      General: {
        min: 28,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
    },
  },
  WY: {
    renewalInterval: 'Biennial',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '12/31',
    link: 'https://online.wypepls.org/#/',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  AZ: {
    renewalInterval: 'Triennial',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: 'initialDay',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  CA: {
    renewalInterval: 'Biennial',
    PDH: 60,
    rolloverPDH: 0,
    renewalDay: 'california',
    categories: {
      General: {
        min: 60,
        required: true,
        rollup: true,
      },
    },
  },
  CO: {
    renewalInterval: 'OddYears',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: '10/31',
    link: 'https://apps2.colorado.gov/dora/licensing/default.aspx',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  CT: {
    renewalInterval: 'n/a',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: 'Unknown',
    link: 'https://elicense.ct.gov',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  DE: {
    renewalInterval: 'EvenYears',
    PDH: 24,
    rolloverPDH: 0,
    renewalDay: '6/30',
    link: 'https://www.dape.org/Users/login/type:ProfessionalEngineer',
    categories: {
      General: {
        min: 22,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        max: 6,
        required: true,
        rollup: true,
      },
    },
  },
  FL: {
    renewalInterval: 'OddYears',
    PDH: 18,
    rolloverPDH: 0,
    renewalDay: '2/28',
    link: 'http://www.myfloridalicense.com/dbpr/',
    categories: {
      General: {
        min: 16,
        required: true,
        rollup: true,
      },
      'Florida Laws and Rules': {
        min: 1,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  HI: {
    renewalInterval: 'EvenYears',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: '4/30',
    link: 'https://mypvl.dcca.hawaii.gov/',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  LA: {
    renewalInterval: 'Biennial',
    PDH: 15,
    rolloverPDH: 7,
    renewalDay: 'louisiana',
    '24HourMax': 8,
    link: 'https://lola.lapels.com',
    categories: {
      General: {
        min: 13,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
    },
  },
  MA: {
    renewalInterval: 'EvenYears',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: '6/30',
    link: 'https://elicensing21.mass.gov/CitizenAccess/Default.aspx',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  MT: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '6/30',
    link: 'https://ebiz.mt.gov/POL/Default.aspx',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  NV: {
    renewalInterval: 'nevada',
    PDH: 30,
    rolloverPDH: 0,
    renewalDay: 'nevada',
    link: 'https://nvboeonline.org/UI/License_Renewal.aspx',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
      Ethics: {
        min: 2,
        required: true,
        rollup: true,
      },
      'Nevada NAC and NRS': {
        min: 1,
        required: true,
        rollup: true,
      },
    },
  },
  RI: {
    renewalInterval: 'Annual',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: '6/30',
    link: 'https://elicensing.ri.gov/',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
  VT: {
    renewalInterval: 'EvenYears',
    PDH: 30,
    rolloverPDH: 15,
    renewalDay: '7/31',
    link: 'https://secure.professionals.vermont.gov/prweb/PRServlet/app/default/ybVBleIGIHMlPa8qpM9BaiNEZfDPENuF*/!STANDARD',
    categories: {
      General: {
        min: 30,
        required: true,
        rollup: true,
      },
    },
  },
  WA: {
    renewalInterval: 'Biennial',
    PDH: 0,
    rolloverPDH: 0,
    renewalDay: 'birthDay',
    link: 'https://professions.dol.wa.gov/s/saw-help',
    categories: {
      General: {
        min: 0,
        required: true,
        rollup: true,
      },
    },
  },
};
