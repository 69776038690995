import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';

export function ResultCard({
  id,
  logType,
  title,
  trainingGroup,
  timeWatched,
  videoLength,
  log,
  setRefreshNeeded,
}) {
  const navigation = useNavigation();

  function handleResultClick() {
    // Navigate to appropriate details screen (if necessary)
    navigation.navigate('Video', { id, title, log, setRefreshNeeded });
  }

  return (
    <TouchableOpacity style={styles.container} onPress={handleResultClick}>
      <View style={styles.textContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={styles.subText}>
          Training Group: {trainingGroup} • Type: {logType}
        </Text>
        <Text style={styles.subText}>
          Time Logged: {log.logVideoOnLength ? parseFloat(videoLength).toFixed(2) : parseFloat(timeWatched).toFixed(2)} hrs
          {'\n'}Date: {log.training_date ? log.training_date : log.updated_at}
        </Text>
      </View>
      {log.thumbnail ? <Image style={styles.thumbnail} source={{uri: log.thumbnail}} /> : <></>}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row', // Add this line
    backgroundColor: 'white',
    borderRadius: 10,
    color: 'black',
    marginVertical: 5,
    shadowColor: 'black',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    borderWidth: 2,  // Add this line
    borderColor: "#ffff08",  // Add this line
  },
  thumbnail: {
    width: 50,  // Set your preferred width
    height: 50, // Set your preferred height
    borderRadius: 25, // Optional: make it rounded
    marginRight: 10, // Optional: add some margin to separate it from the text
  },
  textContainer: {
    flex: 1,  // Add this line
    flexDirection: 'column',
    padding: 10,
  },
  titleText: {
    fontSize: 16,
    marginBottom: 5,
  },
  subText: {
    fontSize: 12,
    color: 'gray',
  },
});
