import { useState } from 'react';

import { useGetSearchResults } from './api/useGetSearchResults';

export function useSearch() {
  const [searchedTerm, setSearchedTerm] = useState('');
  const { data, error, isFetching } = useGetSearchResults(searchedTerm);

  function onSearch(searchTerm) {
    setSearchedTerm(searchTerm);
  }

  return {
    error,
    isFetching,
    onSearch,
    results: data?.items || [],
    searchedTerm,
  };
}
