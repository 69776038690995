import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Modal,
  Linking,
} from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import DateTimePicker from '../../../components/DateTimePickerWrapper';
import { PRIMARY_COLOR, BLUE_COLOR, PEStateData } from '../../../constants';
import useApi from '../../../hooks/useApi';
import keepConnectApi from '../../../api/keepConnectsApi';
import Button from '../../../components/Button';
import Dropdown1 from '../../../components/Dropdown';
import { useSettings } from '../../../components/SettingsContext';
import ProgressBarsComponent from '../../../components/ProgressBarsComponent';
import { FontAwesome } from '@expo/vector-icons';
import { create } from 'apisauce';
import alert from '../../../components/alert';

export function PEStateDefault({ route, newState }) {
  //const route = useRoute();
  const sendCertificationToServer = useApi(keepConnectApi.sendCertificationToServer);
  const deleteCertificationFromServer = useApi(keepConnectApi.deleteCertificationFromServer);
  const getLogsForReconciliation = useApi(keepConnectApi.getLogsForReconciliation);
  const addRecordLedger = useApi(keepConnectApi.addRecordLedger);
  const { settings, addTrainingGroup, removeTrainingGroup } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [certificationType, setCertificationType] = useState('PE');
  const [peState, setPeState] = useState(route.params?.log?.certLevel2 || newState);
  const [renewalDate, setRenewalDate] = useState(new Date());
  const [continuingHours, setContinuingHours] = useState('');
  const [additionalData, setAdditionalData] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [dbIndex, setDbIndex] = useState(null);
  const [previousRenewalDate, setPreviousRenewalDate] = useState(new Date());
  const [initialLicenseDate, setInitialLicenseDate] = useState(new Date());
  const [previousRenewalRecords, setPreviousRenewalRecords] = useState([]);
  const [logs, setLogs] = useState(null);
  const [ledger, setLedger] = useState({});
  const [sortedLogs, setSortedLogs] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [splitHours, setSplitHours] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);

  const showCategoryModal = (log) => {
    setSelectedLog(log);
    setGroupModalVisible(true);
  };

  useEffect(() => {
    const { log } = route.params || {};
    if (log) {
      //console.log("Log: ", log);
      setCertificationType(log.certification_type || '');
      setPeState(log.certLevel2 || newState || '');
      setRenewalDate(new Date(log.renewal_date + 'T00:00:00') || new Date());
      setInitialLicenseDate(new Date(log.initial_date + 'T00:00:00') || new Date());
      setContinuingHours(log.continuing_hours || '');
      setLicenseNumber(log.license_number || '');
      setDbIndex(log.id || null);
      setPreviousRenewalDate(new Date(log.previous_renewal_date + 'T00:00:00') || new Date());
      setAdditionalData(log.additional_data || '');
      let updatedLedger = { ...JSON.parse(log.ledger) };
      const categories = PEStateData[peState].categories;

      //console.log('imported renewal date: ', new Date(log.renewal_date + 'T00:00:00'));

      // Add any missing categories with 0 total
      Object.keys(categories).forEach((category) => {
        if (!updatedLedger[category]) {
          updatedLedger[category] = { total: 0, ids: [] };
        }
      });

      if (!updatedLedger.CarryOver) {
        updatedLedger.CarryOver = { total: 0, ids: [] };
      }
      if (!updatedLedger.Unreconciled) {
        updatedLedger.Unreconciled = { total: 0, ids: [] };
      }
      //console.log('Update Ledger:', JSON.stringify(updatedLedger));

      setLedger(updatedLedger);
    }
  }, [refreshNeeded]);

  useEffect(() => {
    if (!logs || !ledger) return;
    processLedger({ ...ledger });
  }, [logs]);

  const processLedger = (ledgerCopy) => {
    const categoryProperties = PEStateData[peState].categories;
    let sortedLogsTemp = {};

    // Initialize all categories in sortedLogsTemp, even if they are empty.
    Object.keys(ledger).forEach((category) => {
      if (category !== 'SumTotal') {
        sortedLogsTemp[category] = [];
        ledgerCopy[category].total = 0;
      }
    });

    let unreconciledTotal = 0; // Initialize a variable to keep track of the total sum for "Unreconciled"

    logs.forEach((log) => {
      let foundCategory = null;

      // Check if this log's id is already recorded in any ledger category
      for (const [category, { ids }] of Object.entries(ledgerCopy)) {
        if (category === 'SumTotal') continue;
        ids.forEach((item) => {
          if (item.id === log.id) {
            foundCategory = category;
            // If the item has a child object, create a new log
            let logHours = 0;
            if (item.child) {
              const newLog = { ...log, ...item.child };
              sortedLogsTemp[category].push(newLog);
              logHours = item.child.hours;
            } else {
              sortedLogsTemp[category].push(log);
              logHours = log.hours;
            }
            // Update the category total based on logHours
            ledgerCopy[foundCategory].total = parseInt(ledgerCopy[foundCategory].total, 10) + parseInt(logHours, 10);

            // If rollup is enabled, walk the parent path and sum on the parent

            if (categoryProperties[foundCategory]?.rollup) {
              const parentPath = categoryProperties[foundCategory]?.parentPath?.split('/'); //parentPath includes this entry too
              if (parentPath) {
                for (let parent of parentPath) {
                  if (parent && categoryProperties.hasOwnProperty(parent)) {
                    let max = categoryProperties[parent].max;

                    // Do not roll up to the parent if max is hit
                    if (max !== undefined && results[parent] >= max) {
                      break;
                    }

                    // Roll up to the parent
                    ledgerCopy[parent].total = parseInt(ledgerCopy[parent].total, 10) + parseInt(logHours, 10);
                  }
                }
              }
            }
          }
        });
        if (foundCategory) {
          break;
        }
      }

      // If not found in any category, put it into "Unreconciled" category
      if (!foundCategory) {
        sortedLogsTemp['Unreconciled'].push(log);
        unreconciledTotal = parseInt(unreconciledTotal, 10) + parseInt(log.hours, 10); // Assuming each log has an 'hours' field
      }
    });

    ledgerCopy.SumTotal = { total: 0 };

    let sumTotal = 0;
    Object.keys(ledgerCopy).forEach((category) => {
      if (!categoryProperties[category]?.parentPath) {
        sumTotal += ledgerCopy[category].total;
      }
    });

    ledgerCopy.SumTotal.total = sumTotal;
    // Add the total sum for "Unreconciled" logs to sortedLogsTemp
    sortedLogsTemp['Unreconciled'].total = parseInt(unreconciledTotal, 10);

    //console.log("Processed Ledger: ", ledgerCopy);
    setSortedLogs(sortedLogsTemp);
    setLedger(ledgerCopy);
    //console.log("Sorted Logs: ", sortedLogsTemp);
  };

  const RenewalInterval = {
    ANNUAL: 'Annual',
    BIENNIAL: 'Biennial',
    TRIENNIAL: 'Triennial',
    EVEN_YEARS: 'EvenYears',
    ODD_YEARS: 'OddYears',
    ODD_EVEN_LICENSE: 'OddEvenLicense', //on license number even/odd
    ODD_EVEN_INITIAL: 'OddEvenInitial', //on initial license date even/odd year
    KS_LASTNAME: 'LastNameALEvenMZOddYears',
    KY_NE_LASTNAME: 'LastNameAKOddLZEven',
    // ... add more intervals as needed
  };

  function getNextExpirationDate(renewalDate, renewalInterval, renewalDay) {
    let newDate = new Date(renewalDate);
    const birthDate = new Date(settings.user_settings?.profile?.dateOfBirth);
    const birthMonth = birthDate.getMonth();
    const lastName = settings.user_settings?.profile?.lastName;
    newDate.setDate(1); // Temporarily set to the first day of any month

    // Update year based on renewalInterval
    switch (renewalInterval) {
      case RenewalInterval.ANNUAL:
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      case RenewalInterval.BIENNIAL:
        newDate.setFullYear(newDate.getFullYear() + 2);
        break;
      case RenewalInterval.TRIENNIAL:
        newDate.setFullYear(newDate.getFullYear() + 3);
        break;
      case RenewalInterval.EVEN_YEARS:
        newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1));
        break;
      case RenewalInterval.ODD_YEARS:
        newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 !== 0 ? 2 : 1));
        break;
      case RenewalInterval.ODD_EVEN_LICENSE:
        if (licenseNumber % 2 === 0) {
          // Even license number
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1));
        } else {
          // Odd license number
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 !== 0 ? 2 : 1));
        }
        break;

      case RenewalInterval.ODD_EVEN_INITIAL:
        if (initialLicenseDate) {
          const initialDate = new Date(initialLicenseDate);
          newDate.setFullYear(newDate.getFullYear() + (initialDate.getFullYear() % 2 === newDate.getFullYear() % 2 ? 2 : 1));
        }
        break;

      case RenewalInterval.KS_LASTNAME:
        if (lastName.charAt(0).toUpperCase() >= 'A' && lastName.charAt(0).toUpperCase() <= 'L') {
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1)); // Even years
        } else {
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 !== 0 ? 2 : 1)); // Odd years
        }
        break;

      case RenewalInterval.KY_NE_LASTNAME:
        if (lastName.charAt(0).toUpperCase() >= 'A' && lastName.charAt(0).toUpperCase() <= 'K') {
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 !== 0 ? 2 : 1)); // Odd years
        } else {
          newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1)); // Even years
        }
        break;
      // ... handle other cases
      default:
        break;
    }

    if (renewalDay) {
      switch (renewalDay) {
        case 'birthDay':
          newDate.setMonth(birthMonth - 1);
          newDate.setDate(1);
          break;
        case 'birthMonth1':
          newDate.setMonth(birthMonth - 1);
          newDate.setDate(1);
          break;
        case 'birthMonth2':
          newDate.setMonth(birthMonth);
          newDate.setDate(0); // Last day of previous month
          break;
        case 'initialDay':
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            newDate.setDate(initialDate.getDate());
          }
          break;
        case 'initialDay2': //last day of initial month
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            newDate.setMonth(initialDate.getMonth() + 1);
            newDate.setDate(0); // Last day of initial month
          }
          break;
        case 'oregon':
          if (lastName) {
            const firstLetter = lastName.charAt(0).toUpperCase();
            const currentYear = newDate.getFullYear();
            const nextOddYear = currentYear + (currentYear % 2 === 0 ? 1 : 2);
            const nextEvenYear = currentYear + (currentYear % 2 === 0 ? 2 : 1);

            if (firstLetter >= 'A' && firstLetter <= 'F') {
              newDate.setFullYear(nextOddYear);
              newDate.setMonth(11); // December (0-indexed)
              newDate.setDate(31); // 31st
            } else if (firstLetter >= 'G' && firstLetter <= 'K') {
              newDate.setFullYear(nextOddYear);
              newDate.setMonth(5); // June (0-indexed)
              newDate.setDate(30); // 30th
            } else if (firstLetter >= 'L' && firstLetter <= 'R') {
              newDate.setFullYear(nextEvenYear);
              newDate.setMonth(11); // December (0-indexed)
              newDate.setDate(31); // 31st
            } else if (firstLetter >= 'S' && firstLetter <= 'Z') {
              newDate.setFullYear(nextEvenYear);
              newDate.setMonth(5); // June (0-indexed)
              newDate.setDate(30); // 30th
            } else {
              throw new Error('Invalid last name');
            }
          }
          break;
        case 'texas':
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            const month = initialDate.getMonth();
            if (month < 3) {
              // 1st quarter
              newDate.setMonth(11); // December (0-indexed)
              newDate.setDate(31); // 31st
            } else if (month < 6) {
              // 2nd quarter
              newDate.setMonth(2); // March (0-indexed)
              newDate.setDate(31); // 31st
            } else if (month < 9) {
              // 3rd quarter
              newDate.setMonth(5); // June (0-indexed)
              newDate.setDate(30); // 30th
            } else {
              // 4th quarter
              newDate.setMonth(8); // September (0-indexed)
              newDate.setDate(30); // 30th
            }
            // Increment the year for the next renewal date
            newDate.setFullYear(newDate.getFullYear() + 1);
          }
          break;
        case 'california':
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            newDate.setDate(initialDate.getDate());
          }
          break;
        case 'louisiana':
          if (initialLicenseDate) {
            const initialDate = new Date(initialLicenseDate);
            // Check if the initial license date is in an odd or even year
            if (initialDate.getFullYear() % 2 === 0) {
              // Even year
              newDate.setMonth(2); // March (0-indexed)
              newDate.setDate(31); // 31st
            } else {
              // Odd year
              newDate.setMonth(8); // September (0-indexed)
              newDate.setDate(30); // 30th
            }
            // Set the year to the next even year for renewal
            newDate.setFullYear(newDate.getFullYear() + (newDate.getFullYear() % 2 === 0 ? 2 : 1));
          }
          break;
        case 'nevada':
          if (lastName) {
            const firstLetter = lastName.charAt(0).toUpperCase();
            const currentYear = newDate.getFullYear();
            const nextOddYear = currentYear + (currentYear % 2 === 0 ? 1 : 2);
            const nextEvenYear = currentYear + (currentYear % 2 === 0 ? 2 : 1);

            if (firstLetter >= 'A' && firstLetter <= 'E') {
              newDate.setFullYear(nextOddYear);
              newDate.setMonth(0); // January (0-indexed)
              newDate.setDate(1); // 1st
            } else if (firstLetter >= 'F' && firstLetter <= 'K') {
              newDate.setFullYear(nextOddYear);
              newDate.setMonth(6); // July (0-indexed)
              newDate.setDate(1); // 1st
            } else if (firstLetter >= 'L' && firstLetter <= 'R') {
              newDate.setFullYear(nextEvenYear);
              newDate.setMonth(0); // January (0-indexed)
              newDate.setDate(1); // 1st
            } else if (firstLetter >= 'S' && firstLetter <= 'Z') {
              newDate.setFullYear(nextEvenYear);
              newDate.setMonth(6); // July (0-indexed)
              newDate.setDate(1); // 1st
            } else {
              throw new Error('Invalid last name');
            }
          }
          break;
        default:
          //console.log(newDate, renewalDay);
          const [month, day] = renewalDay.split('/');
          newDate.setDate(1); // Temporarily set to the first day of any month
          newDate.setMonth(parseInt(month, 10) - 1);
          newDate.setDate(parseInt(day, 10));
          //console.log("Default Day of Month", month, day, newDate);
          break;
      }
    }
    console.log('New Date: ', newDate);
    return newDate;
  }

  const navigation = useNavigation();

  const collectIds = (obj, startKey) => {
    let ids = [];

    const helper = (subObj) => {
      if (subObj && subObj.id) {
        ids.push(subObj.id);
      }
      for (const key in subObj) {
        if (typeof subObj[key] === 'object' && key !== 'owner') {
          helper(subObj[key]);
        }
      }
    };

    helper(obj[startKey]);

    return ids.join(',');
  };

  //creates an array of training groups to be added to the user's training groups:
  //[{newGroup: "PE", groupOwner: "NC"}, {newGroup: "PMP", groupOwner: "NC", parentPath: ["PE"]}
  //the add/remove training group functions expect this array (removal gets reversed to remove children first)
  const createTrainingGroups = (reverse = false) => {
    const trainingGroupsTemp = [
      { newGroup: 'PE', groupOwner: peState },
      { newGroup: peState, groupOwner: peState, parentPath: ['PE'] },
    ];

    // Find the categories for the specified state
    const certData = PEStateData[peState];

    if (certData && certData.categories) {
      Object.keys(certData.categories).forEach((category) => {
        const additionalPath = certData.categories[category].parentPath
          ? certData.categories[category].parentPath.split('/').filter((item) => item !== '')
          : [];
        trainingGroupsTemp.push({
          newGroup: category,
          groupOwner: peState,
          parentPath: ['PE', peState].concat(additionalPath),
        });
      });
    }

    return reverse ? trainingGroupsTemp.reverse() : trainingGroupsTemp;
  };

  const sendDataToServer = async (ledgerToSend) => {
    if (!certificationType || (!peState && certificationType === 'PE') || !licenseNumber) {
      alert('Error', 'All fields must be filled out.');
      return;
    }

    setIsLoading(true);

    //console.log("Training Groups TEmp:",trainingGroupsTemp);
    addTrainingGroup(createTrainingGroups(false));

    //collect ID's starting with PE subject and starting on PE State. Only traverse that state (and not all states under PE), then add PE to the array as well
    const peStateChildIds = collectIds(settings.training_groups['PE'], peState); //this walks the tree starting at node peState and collects all the ids
    const peRootId = settings.training_groups['PE'] ? settings.training_groups['PE'].id : null;
    const trainingGroupsToSubmit = peRootId ? `${peStateChildIds},${peRootId}` : peStateChildIds; //add 'PE' id to the array too (if exists)

    const payload = {
      certification_type: certificationType,
      certLevel2: peState,
      initial_date: initialLicenseDate.toISOString().split('T')[0],
      previous_renewal_date: previousRenewalDate.toISOString().split('T')[0],
      renewal_date: renewalDate.toISOString().split('T')[0],
      continuing_hours: continuingHours,
      license_number: licenseNumber,
      ledger: JSON.stringify(ledgerToSend),
      trainingGroup: trainingGroupsToSubmit,
      dbIndex,
    };

    //console.log('Payload: ', JSON.stringify(payload));

    const response = await sendCertificationToServer.request(payload);

    //console.log(response.data);
    setIsLoading(false);
    route.params.setRefreshNeeded(true);
    navigation.goBack();
  };

  const deleteDataFromServer = async () => {
    setIsLoading(true);
    const payload = {
      dbIndex,
    };
    const response = await deleteCertificationFromServer.request(payload);
    //console.log(response.data);
    removeTrainingGroup(createTrainingGroups(true));
    setIsLoading(false);
    route.params?.setRefreshNeeded(true);
    navigation.goBack();
  };

  const onChangeDate = (event, selectedDate) => {
    if (selectedDate) {
      setRenewalDate(selectedDate);
    }
  };

  const onChangeInitialDate = (event, selectedDate) => {
    if (selectedDate) {
      setInitialLicenseDate(selectedDate);
      setRenewalDate(getNextExpirationDate(previousRenewalDate, PEStateData[peState].renewalInterval, PEStateData[peState].renewalDay));
    }
  };

  const onChangePreviousDate = (event, selectedDate) => {
    if (selectedDate) {
      console.log('Pressed Date: ', selectedDate);
      setPreviousRenewalDate(selectedDate);
      console.log('Next Exp.: ');
      setRenewalDate(getNextExpirationDate(selectedDate, PEStateData[peState].renewalInterval, PEStateData[peState].renewalDay));
    }
  };

  const setLedgerAndSave = (ledgerToSave) => {
    setLedger(ledgerToSave);
    sendDataToServer(ledgerToSave);
  };

  const clearLedger = () => {
    let updatedLedger = {};
    const categories = PEStateData[peState].categories;

    //console.log('imported renewal date: ', new Date(log.renewal_date + 'T00:00:00'));

    // Add any missing categories with 0 total
    Object.keys(categories).forEach((category) => {
      if (!updatedLedger[category]) {
        updatedLedger[category] = { total: 0, ids: [] };
      }
    });

    if (!updatedLedger.CarryOver) {
      updatedLedger.CarryOver = { total: 0, ids: [] };
    }
    if (!updatedLedger.Unreconciled) {
      updatedLedger.Unreconciled = { total: 0, ids: [] };
    }
    //console.log('Update Ledger:', JSON.stringify(updatedLedger));

    setLedger(updatedLedger);
    if (route.params?.setLedger) route.params.setLedger(updatedLedger);
    return updatedLedger;
  };

  const recordRenewal = async () => {
    const response = await addRecordLedger.request({
      certification_id: dbIndex,
      periodEndDate: renewalDate.toISOString().split('T')[0],
      ledger: JSON.stringify(ledger),
    });
    let nextRenewalDate = getNextExpirationDate(renewalDate, PEStateData[peState].renewalInterval, PEStateData[peState].renewalDay);
    setPreviousRenewalDate(renewalDate); //set the previous renewal to the current expiration date
    setRenewalDate(nextRenewalDate);

    setIsLoading(true);

    const peStateChildIds = collectIds(settings.training_groups['PE'], peState); //this walks the tree starting at node peState and collects all the ids
    //console.log('PE State: ', peState, peStateChildIds);
    const peRootId = settings.training_groups['PE'] ? settings.training_groups['PE'].id : null;
    const trainingGroupsToSubmit = peRootId ? `${peStateChildIds},${peRootId}` : peStateChildIds; //add 'PE' id to the array too (if exists)
    //console.log('Training Groups to Submit:', trainingGroupsToSubmit);
    const formattedRenewalDate = renewalDate.toISOString().split('T')[0];
    const formattedInitialDate = initialLicenseDate.toISOString().split('T')[0];
    const payload = {
      certification_type: certificationType,
      certLevel2: peState,
      initial_date: formattedInitialDate,
      previous_renewal_date: renewalDate.toISOString().split('T')[0],
      renewal_date: nextRenewalDate.toISOString().split('T')[0],
      continuing_hours: continuingHours,
      license_number: licenseNumber,
      ledger: JSON.stringify(clearLedger()),
      trainingGroup: trainingGroupsToSubmit,
      dbIndex,
    };

    console.log('Payload: ', JSON.stringify(payload));

    const response2 = await sendCertificationToServer.request(payload);

    //console.log(response2.data);
    setIsLoading(false);
    if (route.params?.setRefreshNeeded) route.params.setRefreshNeeded(true);
    navigation.goBack();
  };

  const reconcilePDH = async () => {
    const response = await getLogsForReconciliation.request({
      certification_id: dbIndex,
    });
    // Assume that response.data returns an object {logs: [], ledger: {}}
    console.log('Reconcile Pull :', JSON.stringify(response.data[0]));
    setLogs(response.data);
    setIsLoading(false);
    navigation.navigate('ViewLedger', {
      log: route.params?.log,
      logsToLoad: logs,
      setRefreshNeeded: route.params?.setRefreshNeeded,
      setLedger: setLedgerAndSave,
    });
    //setCategoryModalVisible(true);
  };

  const renewOnline = async () => {
    const url = PEStateData[peState].link;

    if (url) {
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      } else {
        console.log(`Can't handle the URL: ${url}`);
      }
    } else {
      console.log('URL is not defined.');
    }
  };

  const onChangeItemCategory = (newCategory) => {
    if (selectedLog.id === null) return;

    let updatedLedger = { ...ledger };

    // Search for and remove the log (or its child) from its current category
    const categoryKeys = Object.keys(updatedLedger);
    for (let category of categoryKeys) {
      if (category === 'SumTotal') continue;
      const logIndex = updatedLedger[category].ids.findIndex((log) =>
        log.childID ? log.childID === selectedLog.childID : log.id === selectedLog.id,
      );
      if (logIndex > -1) {
        const removedLog = updatedLedger[category].ids.splice(logIndex, 1)[0];
        //updatedLedger[category].total = parseInt(updatedLedger[category].total, 10) - parseInt(removedLog.hours, 10);
        break;
      }
    }

    // Add the log to the new category
    if (!updatedLedger[newCategory]) {
      updatedLedger[newCategory] = { total: 0, ids: [] };
    }
    updatedLedger[newCategory].ids.push({ id: selectedLog.id });
    //updatedLedger[newCategory].total = parseInt(updatedLedger[newCategory].total, 10) + parseInt(selectedLog.hours, 10);

    // Update the ledger
    //setLedger(updatedLedger);
    processLedger(updatedLedger);
  };

  const onSplitOrDuplicateLog = (actionType) => {
    // Create a deep copy of the existing ledger
    let updatedLedger = JSON.parse(JSON.stringify(ledger));

    // Validate that splitHours is within bounds for a 'split' operation
    if (actionType === 'split' && (splitHours <= 0 || splitHours >= selectedLog.hours)) {
      console.error('Invalid number of hours for splitting.');
      return;
    }

    // Find the category where the log currently resides
    let foundCategory = null;
    let foundIndex = -1;
    for (const [category, { ids }] of Object.entries(updatedLedger)) {
      const logIndex = ids.findIndex((item) => (selectedLog.childID ? item.child?.childID === selectedLog.childID : item.id === selectedLog.id));
      if (logIndex > -1) {
        foundCategory = category;
        foundIndex = logIndex;
        break;
      }
    }

    if (!foundCategory) {
      // Log not found in any category, maybe handle this case differently.
      console.error('Log not found in any category.');
      return;
    }

    // Generate new childID
    const newChildID = `${selectedLog.childID ? selectedLog.childID : selectedLog.id}_${Date.now()}`;

    // Create the child object
    const childObject = {
      childID: newChildID,
      parentID: selectedLog.childID || selectedLog.id,
      hours: actionType === 'split' ? splitHours : selectedLog.hours,
      childType: actionType,
    };

    // Add this as a child log in the appropriate category
    updatedLedger[foundCategory].ids.push({
      id: selectedLog.id,
      child: childObject,
    });

    if (actionType === 'split') {
      // Update the original log's hours
      if (selectedLog.childID) {
        updatedLedger[foundCategory].ids[foundIndex].child.hours =
          parseInt(updatedLedger[foundCategory].ids[foundIndex].child.hours, 10) - parseInt(splitHours, 10);
      } else {
        const targetLog = logs.find((log) => log.id === selectedLog.id);
        if (targetLog) targetLog.hours = parseInt(targetLog.hours, 10) - parseInt(splitHours, 10);
      }
    } else if (actionType === 'duplicate') {
      // Update the total hours for the category
      //updatedLedger[foundCategory].total = parseInt(updatedLedger[foundCategory].total, 10) + parseInt(childObject.hours, 10);
    }

    // Update the ledger state
    processLedger(updatedLedger);
    setGroupModalVisible(false);
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" color={PRIMARY_COLOR} />
      ) : (
        <>
          <View style={styles.categoryBox}>
            <Text style={{ fontWeight: 'bold' }}>Professional Engineer (PE)</Text>
            <Text>State: {peState}</Text>
          </View>

          <View style={styles.categoryBox}>
            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Initial License:</Text>
              <DateTimePicker value={initialLicenseDate} mode="date" display="default" onChange={onChangeInitialDate} />
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Last Renewal:</Text>
              <DateTimePicker value={previousRenewalDate} mode="date" display="default" onChange={onChangePreviousDate} />
            </View>

            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>Expiration Date:</Text>
              <DateTimePicker value={renewalDate} mode="date" display="default" onChange={onChangeDate} />
            </View>

            {/* <Text style={styles.leftAlignedLabel}>Continuing Hours:</Text>
          <TextInput
            placeholder="Continuing Hours"
            value={String(continuingHours)}
            editable={false}
            style={styles.input}
          /> */}
            <View style={styles.rowContainer}>
              <Text style={styles.leftAlignedLabel}>License Number:</Text>
              <TextInput placeholder="License Number" value={licenseNumber} onChangeText={setLicenseNumber} style={styles.input} />
            </View>
          </View>

          {dbIndex && <ProgressBarsComponent data={route.params?.log} />}
          {/* <Button title="View Ledgers" onPress={()=>navigation.navigate('ViewLedger', { log: route.params?.log })} />
          <Button title="Reconcile PDH's" onPress={reconcilePDH} />
          <Button title="Record Renewal" onPress={recordRenewal} />
          <Button title="Save Certification" onPress={()=>sendDataToServer()} />
          {dbIndex && <Button title="Delete Certification" onPress={()=>deleteDataFromServer()} />} */}

          {dbIndex && (
            <>
              <View style={styles.iconRow}>
                <TouchableOpacity style={styles.iconBox} onPress={reconcilePDH}>
                  <FontAwesome name="link" size={24} color="black" />
                  <Text>Associate{'\n'}PDH's</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    navigation.navigate('ViewLedger', {
                      log: route.params?.log,
                      previousLedgers: true,
                    })
                  }
                >
                  <FontAwesome name="book" size={24} color="black" />
                  <Text>Previous{'\n'}Ledgers</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.iconBox} onPress={renewOnline}>
                  <FontAwesome name="credit-card" size={24} color="black" />
                  <Text>Renew{'\n'}Online</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.iconRow}>
                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    alert('Record Renewal', 'This will store the record and advance the renewal and expiration dates to the next interval.', [
                      {
                        text: 'Cancel',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                      },
                      {
                        text: 'Proceed',
                        onPress: () => {
                          recordRenewal();
                        },
                      },
                    ])
                  }
                >
                  <FontAwesome name="camera" size={24} color="black" />
                  <Text>Record{'\n'}Renewal</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.iconBox} onPress={() => sendDataToServer(ledger)}>
                  <FontAwesome name="save" size={24} color="black" />
                  <Text>Save{'\n'}Cert.</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.iconBox}
                  onPress={() =>
                    alert('Delete Certification', 'This will delete this certification from your account. Are you sure?', [
                      {
                        text: 'Cancel',
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                      },
                      {
                        text: 'Proceed',
                        onPress: () => {
                          deleteDataFromServer();
                        },
                      },
                    ])
                  }
                >
                  <FontAwesome name="trash-o" size={24} color="black" />
                  <Text>Delete{'\n'}Cert.</Text>
                </TouchableOpacity>
              </View>
            </>
          )}

          {!dbIndex && (
            <>
              <View style={styles.iconRow}>
                <TouchableOpacity style={styles.iconBox} onPress={() => sendDataToServer(ledger)}>
                  <FontAwesome name="save" size={24} color="black" />
                  <Text>Save Cert.</Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  leftAlignedLabel: {},
  input: {
    flex: 0.8, // This will take 60% of the space
    borderWidth: 1,
    borderColor: 'gray',
    padding: 8,
    borderRadius: 5,
  },
  categoryBox: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#e0e0e0',
    width: '100%',
    padding: 5,
    backgroundColor: '#FFFFFF',
    marginBottom: 5,
    alignItems: 'center',
  },
  modalView: {
    width: 300,
    height: 600,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  logCard: {
    borderWidth: 2,
    borderRadius: 20,
    backgroundColor: BLUE_COLOR,
    borderColor: BLUE_COLOR,
    padding: 10,
    marginBottom: 10,
  },
  logTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  logDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logDetailItem: {
    backgroundColor: '#f0f0f0',
    padding: 5,
    borderRadius: 3,
  },
  brandedBox: {
    borderWidth: 2,
    width: '80%',
    borderColor: BLUE_COLOR, // Replace with your brand color
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  brandedText: {
    // color: YOUR_BRAND_COLOR,  // Replace with your brand color
    fontSize: 16,
    marginVertical: 5,
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 10,
  },
  iconBox: {
    width: 80, // or adjust to your desired width
    height: 80, // ensure this matches width to keep it square
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: PRIMARY_COLOR,
    marginBottom: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // This is for Android
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10, // Spacing between each row
    width: '100%',
    paddingHorizontal: 8, // Padding to ensure they don’t touch the edges
  },
});
