import { useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";

import AuthContext from "./context";
import authStorage from "./storage";

 const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    // Note the use of async inside useEffect
    const checkToken = async () => {
      const token = await authStorage.getToken();
      if (token) {
        const decodedToken = jwtDecode(token);
        // Check if the token has expired
        if (decodedToken.exp * 1000 < Date.now()) {
          logOut();
        }
      }
    };
  
    checkToken();
  }, []);
  

  const logIn = (authToken) => {
    const user = jwtDecode(authToken);
    setUser(user);
    authStorage.storeToken(authToken);
  };

  const logOut = () => {
    setUser(null);
    authStorage.removeToken();
  };

  return { user, logIn, logOut };
};

export default useAuth;
