import { useState } from 'react';

export function useSearchBar(onSearch) {
  const [searchTerm, setSearchTerm] = useState('');

  function handleSearchTermChange(value) {
    setSearchTerm(value);
  }

  function handleSearch() {
    onSearch(searchTerm);
  }

  function handleClear() {
    setSearchTerm('');
  }

  return {
    handleClear,
    handleSearch,
    handleSearchTermChange,
    searchTerm,
  };
}
