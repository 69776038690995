import { create } from 'apisauce';
import authStorage from "../auth/storage";

const apiClient = create({
    baseURL: 'https://certifymeup.com/wp-json',
    responseType: 'arraybuffer',
    timeout: 45000
});

apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await authStorage.getToken();
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
  });

export default apiClient;