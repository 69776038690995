import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Platform, ScrollView, ActivityIndicator, TextInput } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import * as DocumentPicker from 'expo-document-picker';
import DateTimePicker from '../../components/DateTimePickerWrapper';
import { PRIMARY_COLOR } from '../../constants';
import useApi from '../../hooks/useApi';
import keepConnectApi from '../../api/keepConnectsApi';
import Button from '../../components/Button';
//import MultiSelectComponent from '../../components/MultiSelect';
import TrainingGroupSelector from '../../components/TrainingGroupSelector';
import { useSettings } from '../../components/SettingsContext';

export function UploadFile() {
  const route = useRoute();
  const sendFileToServer = useApi(keepConnectApi.sendFileToServer);
  const deleteFileFromServer = useApi(keepConnectApi.deleteFileFromServer);
  const [fileData, setFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hours, setHours] = useState('');
  const [title, setTitle] = useState('');
  const [trainingGroup, setTrainingGroup] = useState('');
  const [notes, setNotes] = useState('');
  const [dbIndex, setDbIndex] = useState();
  const [trainingDate, setTrainingDate] = useState(new Date());
  const [selectedTrainingGroups, setSelectedTrainingGroups] = useState([]);
  const { settings, addTrainingGroup, removeTrainingGroup } = useSettings();

  const navigation = useNavigation();

  // Assuming you get training groups from settings and it's an array like ['Group1', 'Group2', ...]
  const trainingGroups = settings.training_groups || []; // Replace this with where you actually get the training groups from.
  // Convert the trainingGroups to the expected format for MultiSelectComponent
  // const data = trainingGroups.map((group, index) => {
  //   return { label: group, value: index.toString() };
  // });

  useEffect(() => {
    if (route.params?.sharedFile) {
      (async () => {
        const fileUri = route.params.sharedFile.uri;
        const fileName = route.params.sharedFile.fileName;

        let actualFileUri = fileUri;

        // Check if the URI is a content URI (common on Android)
        if (fileUri.startsWith('content://')) {
          const tempDir = FileSystem.cacheDirectory;
          const tempPath = `${tempDir}${fileName}`;

          // Using copyAsync to copy the file to a local directory
          await FileSystem.copyAsync({
            from: fileUri,
            to: tempPath,
          });

          actualFileUri = tempPath;
        }

        try {
          const fileContent = await FileSystem.readAsStringAsync(actualFileUri, {
            encoding: FileSystem.EncodingType.Base64,
          });

          setFileData({
            fileName,
            fileContent,
          });
        } catch (readError) {
          console.error('Error reading the file:', readError);
        }
      })().catch((error) => {
        // Handle errors
        console.error(error);
      });
    }

    if (route.params?.log) {
      const { hours, title, training_group, notes, id } = route.params.log;
      setHours(hours || '');
      setTitle(title || '');
      setNotes(notes || '');
      setDbIndex(id);
      setFileData({ fileName: route.params?.log.file_name, fileContent: null });

      // Split the comma-separated string into an array and set it
      if (training_group) {
        const trainingGroupsArray = training_group.split(', ');
        setSelectedTrainingGroups(trainingGroupsArray);
      } else {
        setSelectedTrainingGroups([]);
      }
    }
  }, []);

  const sendDataToServer = async () => {
    setIsLoading(true);
    // TODO: Upload to your server
    console.log(selectedTrainingGroups);
    formattedTrainingDate = trainingDate.toISOString().split('T')[0]; // Convert to "YYYY-MM-DD"
    //console.log(formattedTrainingDate);
    const payload = {
      ...fileData,
      hours,
      trainingGroup: selectedTrainingGroups.join(', '),
      notes,
      title,
      dbIndex,
      training_date: formattedTrainingDate,
    };

    const response = await sendFileToServer.request(payload);
    console.log(response.data);
    setIsLoading(false);
    if (route.params?.setRefreshNeeded) {
      // Call setRefreshNeeded to refresh the previous page
      route.params.setRefreshNeeded(true);
    }
    navigation.goBack();
  };

  const deleteDataFromServer = async () => {
    setIsLoading(true);
    // TODO: Upload to your server
    const payload = {
      dbIndex,
    };

    const response = await deleteFileFromServer.request(payload);
    //console.log(response.data);
    setIsLoading(false);

    // Check if setRefreshNeeded is passed from route.params
    if (route.params?.setRefreshNeeded) {
      // Call setRefreshNeeded to refresh the previous page
      route.params.setRefreshNeeded(true);
    }

    // Navigate back to the previous page
    navigation.goBack();
  };

  const onChangeDate = (event, selectedDate) => {
    if (selectedDate) {
      setTrainingDate(selectedDate);
    }
  };

  const pickDocument = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({ type: '*/*' });
      console.log(result);
      if (result.assets && result.assets.length > 0) {
        const fileName = result.assets[0].name; // This is the file name
        setIsLoading(true);

        if (Platform.OS === 'web') {
          // Handle web platform
          const response = await fetch(result.assets[0].uri);
          const blob = await response.blob();

          // Wrap the FileReader logic in a Promise
          const fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
              resolve(event.target.result); // Resolve the Promise with result
            };

            reader.onerror = reject; // Reject the Promise on error

            reader.readAsDataURL(blob); // Here we read as Data URL to get a Base64 string, similar to what we get for React Native
          });

          setIsLoading(false);
          setFileData({
            fileName,
            fileContent,
          });
        } else {
          // Base64 encoding the file content
          console.log(result.assets.uri);
          const fileContent = await FileSystem.readAsStringAsync(result.assets[0].uri, {
            encoding: FileSystem.EncodingType.Base64,
          });
          setFileData({
            fileName, // You can now access fileName from your state
            fileContent,
          });
        }
        //console.log(fileContent);
        setIsLoading(false);

        // Here you can upload `fileContent` to your server if needed
      }
    } catch (err) {
      console.log('Error picking document: ', err);
    }
  };

  const onSelectGroup = (newSelected) => {
    setSelectedTrainingGroups(newSelected);
  };

  return (
    <ScrollView>
      <View style={styles.container}>
        {isLoading ? (
          <ActivityIndicator size="large" color={PRIMARY_COLOR} />
        ) : (
          <>
            {fileData ? (
              <>
                <Text style={styles.bold}>Received File: {fileData.fileName}</Text>
                <Button title="Choose Another File" onPress={() => pickDocument()} />
              </>
            ) : (
              <Button title="Select File for Upload" onPress={() => pickDocument()} />
            )}
            <Text style={styles.leftAlignedLabel}>Title:</Text>
            <TextInput placeholder="Title" value={title} onChangeText={setTitle} style={styles.input} />
            <Text style={styles.leftAlignedLabel}>Hours:</Text>
            <TextInput placeholder="Hours" value={hours} onChangeText={setHours} style={styles.input} />
            {/* <Text style={styles.leftAlignedLabel}>Training Group:</Text>
          <MultiSelectComponent data={data} selected={selectedTrainingGroups} setSelected={setSelectedTrainingGroups} /> */}
            <Text style={styles.leftAlignedLabel}>Training Group:</Text>
            <TrainingGroupSelector groupData={settings.training_groups} selected={selectedTrainingGroups} onSelect={onSelectGroup} />
            <Text style={styles.leftAlignedLabel}>Notes:</Text>
            <TextInput placeholder="Notes" value={notes} onChangeText={setNotes} style={styles.input} />
            <Text>Date of Training:</Text>
            <DateTimePicker testID="dateTimePicker" value={trainingDate} mode="date" display="default" onChange={onChangeDate} />

            <Button title="Save Log" onPress={() => sendDataToServer()} />
            <Button title="Delete Log" onPress={() => deleteDataFromServer()} />
          </>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  uploadButton: {
    backgroundColor: PRIMARY_COLOR,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    borderRadius: 5,
  },
  uploadButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    width: '80%',
    padding: 8,
    margin: 8,
    borderRadius: 5,
  },
  leftAlignedLabel: {
    textAlign: 'left',
    alignSelf: 'stretch', // Take full width
    paddingLeft: '10%', // You can adjust this to align it properly
  },
});
