import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Text, ActivityIndicator, Modal, TouchableOpacity } from 'react-native';
import DismissKeyboard from '../../components/DismissKeyboard';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import { PRIMARY_COLOR } from '../../constants';
import { ResultCard } from './components/ResultCard/ResultCard';
import DateTimePicker from '../../components/DateTimePickerWrapper';
import Dropdown1 from '../../components/Dropdown';

import Button from '../../components/Button';
import useAuth from '../../auth/useAuth';
import keepConnectApi from '../../api/keepConnectsApi';
import useApi from '../../hooks/useApi';
import { useSettings } from '../../components/SettingsContext';
import { Dropdown } from 'react-native-element-dropdown';

export function Certifications() {
  const auth = useAuth();
  const getCertificationsFromServer = useApi(keepConnectApi.getCertificationsFromServer);
  const navigation = useNavigation();
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshNeeded, setRefreshNeeded] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
  });
  const [sortBy, setSortBy] = useState('date');
  const [filterBy, setFilterBy] = useState('all');
  const { settings, setSettings } = useSettings();
  const [savedDateRange, setSavedDateRange] = useState(null);
  const [presetRange, setPresetRange] = useState('12M');
  const [sortType, setSortType] = useState('0');

  // const transformData = (data) => {
  //   const result = {};

  //   data.forEach((item) => {
  //     const { id, certification_type, state, renewal_date, continuing_hours, license_number, initial_date, previous_renewal_date, TotalTrainingHours, TrainingGroupName, SumOfAllTrainingHours } = item;

  //     if (!result[id]) {
  //       result[id] = {
  //         certification_type,
  //         state,
  //         renewal_date,
  //         continuing_hours,
  //         license_number,
  //         initial_date,
  //         previous_renewal_date,
  //         TrainingGroups: [],
  //         SumOfAllTrainingHours,
  //         id,
  //       };
  //     }

  //     result[id].TrainingGroups.push({
  //       TrainingGroupName,
  //       TotalTrainingHours
  //     });
  //   });

  //   return Object.values(result);
  // };

  useEffect(() => {
    if (settings.dateRange) {
      setDateRange(settings.dateRange);
    }
    if (settings.presetRange) {
      setPresetRange(settings.presetRange);
    }
    if (refreshNeeded) {
      loadCertifications();
      setRefreshNeeded(false);
    }
  }, [refreshNeeded]);

  const loadCertifications = async () => {
    const response = await getCertificationsFromServer.request();
    //console.log(response);
    //const transformedData = transformData(response.data);
    setLogData(response.data);
    //console.log(JSON.stringify(transformedData));
    setIsLoading(false);
  };

  const filteredLogs = () => {
    if (filterBy === 'all') {
      return logData;
    }
    return logData.filter((log) => log.training_group === filterBy);
  };

  const totalRecords = filteredLogs().length;
  const totalHours = filteredLogs().reduce((acc, log) => {
    if (log.log_type === 'video') {
      return acc + parseFloat(log.logVideoOnLength ? log.video_length || 0 : log.video_watched_hours || 0);
    }
    return acc + parseFloat(log.hours || 0);
  }, 0);

  useEffect(() => {
    let sortedLogs = [...logData];
    if (sortType === '0') {
      // Ascending order
      sortedLogs.sort((a, b) => new Date(a.training_date) - new Date(b.training_date)); // Assuming 'date' exists in your logData
    } else if (sortType === '1') {
      // Descending order
      sortedLogs.sort((a, b) => new Date(b.training_date) - new Date(a.training_date)); // Assuming 'date' exists in your logData
    }
    setLogData(sortedLogs);
  }, [sortType]);

  const [showModal, setShowModal] = useState({
    dateRange: false,
    sortBy: false,
    filterBy: false,
  });

  const toggleModal = (type) => {
    setShowModal({ ...showModal, [type]: !showModal[type] });
  };

  if (isLoading) {
    return <ActivityIndicator size="large" color={PRIMARY_COLOR} />;
  }

  return (
    <DismissKeyboard>
      <>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
          {/* Icons for triggering modals */}
          <TouchableOpacity onPress={() => toggleModal('dateRange')}>
            <Ionicons name="" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => toggleModal('sortBy')}>
            <Ionicons name="" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => toggleModal('filterBy')}>
            <Ionicons name="" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('CertificationView', { setRefreshNeeded })}>
            <Ionicons name="add" size={24} color="black" />
          </TouchableOpacity>
        </View>

        {/* Sort By Modal */}
        <Modal visible={showModal.sortBy} onRequestClose={() => toggleModal('sortBy')} animationType="slide">
          <View style={styles.modalContainer}>
            <Dropdown1
              labelText="Select Preset Range"
              defaultValueProp={sortType}
              itemsArray={[
                { label: 'Ascending Date', value: '0' },
                { label: 'Descending Date', value: '1' },
              ]}
              onChangeItemProp={(value) => {
                setSortType(value);
                // Calculate and set the date range here
              }}
            />
            <Button title="Close" onPress={() => toggleModal('sortBy')} />
          </View>
        </Modal>

        {/* Filter By Modal */}
        <Modal visible={showModal.filterBy} onRequestClose={() => toggleModal('filterBy')} animationType="slide">
          <View style={styles.modalContainer}>
            {/* <Dropdown1
              labelText="Filter By"
              defaultValueProp={filterBy}
              itemsArray={[
                { label: 'All', value: 'all' },
                ...settings.training_groups?.map(group => ({ label: group, value: group }))
              ]}
              onChangeItemProp={(value) => setFilterBy(value)}
            /> */}
            <Button title="Close" onPress={() => toggleModal('filterBy')} />
          </View>
        </Modal>

        <ScrollView>
          <View style={styles.container}>
            {filteredLogs().length === 0 ? (
              <Text style={styles.center}>No certifications found.</Text>
            ) : (
              <>
                {/* <View style={styles.summaryBox}>
                <Text style={styles.summaryText}>Total Records: </Text>
                <Text style={styles.summaryText}>Total Hours: </Text>
              </View> */}
                {filteredLogs().map((log, index) => (
                  <ResultCard key={index} log={log} setRefreshNeeded={setRefreshNeeded} />
                ))}
              </>
            )}
          </View>
        </ScrollView>
      </>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  spacer: {
    height: 30,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryBox: {
    padding: 16,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
    margin: 8,
    borderColor: 'yellow',
    borderWidth: 1, // You can adjust the border width here
  },
  summaryText: {
    fontWeight: 'bold',
  },
});
