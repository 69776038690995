import { PMIDefault } from "./PMICertifications/PMIDefault";

export const PMIComponent = ({ route, pmiCertification }) => {
  const certLevel2 = route.params?.log?.certLevel2 || pmiCertification;
  //console.log(newState);

  const renderStateSpecificComponent = () => {
    switch (certLevel2) {
      // case 'NY':
      //   return; // <NewYorkPEComponent params={params} />;
      // case 'CA':
      //   return; // <CaliforniaPEComponent params={params} />;
      default:
        return <PMIDefault route={route} pmiCertification={certLevel2} />;
    }
  };

  return (
      renderStateSpecificComponent()
  );
};