import { decode } from 'he';
import React, { useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, Dimensions, KeyboardAvoidingView, ScrollView, StyleSheet, Text, View, TextInput, Switch } from 'react-native';
import YoutubePlayer from 'react-native-youtube-iframe';
import Modal from 'react-native-modal'; // Import Modal
import DismissKeyboard from '../../components/DismissKeyboard';
import { PRIMARY_COLOR } from '../../constants';
import { formatTimeAgo } from '../../utils';
import { useVideo } from './useVideo';
import keepConnectApi from '../../api/keepConnectsApi';
import useApi from '../../hooks/useApi';

import DateTimePicker from '../../components/DateTimePickerWrapper';
import Button from '../../components/Button';
//import MultiSelectComponent from '../../components/MultiSelect';
import TrainingGroupSelector from '../../components/TrainingGroupSelector';
import { useSettings } from '../../components/SettingsContext';

export function Video({ route }) {
  const { data, error, isFetching, onStateChange, send_to_backend, accumulatedTime } = useVideo(route.params.id);
  const [showDescription, setShowDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [trainingGroup, setTrainingGroup] = useState('');
  const [notes, setNotes] = useState('');
  const [dbIndex, setDbIndex] = useState();
  const [isActualTime, setIsActualTime] = useState(true);
  const deleteVideoLogFromServer = useApi(keepConnectApi.deleteVideoLogFromServer);
  const [selectedTrainingGroups, setSelectedTrainingGroups] = useState([]);
  const { settings, addTrainingGroup, removeTrainingGroup } = useSettings();
  const [trainingDate, setTrainingDate] = useState(new Date());

  const navigation = useNavigation();

  // Assuming you get training groups from settings and it's an array like ['Group1', 'Group2', ...]
  const trainingGroups = settings.training_groups || []; // Replace this with where you actually get the training groups from.
  // Convert the trainingGroups to the expected format for MultiSelectComponent
  // const dataTraining = trainingGroups.map((group, index) => {
  //   return { label: group, value: index.toString() };
  // });

  // videos have an aspect ratio of 16:9
  const playerHeight = (Dimensions.get('window').width / 16) * 9;

  useEffect(() => {
    if (route.params?.log) {
      const { video_watched_hours, title, training_group, training_date, notes, id } = route.params.log;
      //accumulatedTime = video_watched_hours;
      setTitle(title || '');
      setNotes(notes || '');
      setDbIndex(id);

      setTrainingDate(new Date(training_date) || new Date());
      // Split the comma-separated string into an array and set it
      if (training_group) {
        const trainingGroupsArray = training_group.split(', ');
        setSelectedTrainingGroups(trainingGroupsArray);
      } else {
        setSelectedTrainingGroups([]);
      }
    }
  }, []);

  const toggleModal = () => {
    if (!isModalVisible) {
      const finalTitle = route.params?.log ? route.params?.log.title : data.snippet.title;
      setTitle(finalTitle);
    }
    setModalVisible(!isModalVisible);
  };

  const onSaveLog = () => {
    let logVideoOnLength = isActualTime ? 0 : 1;
    send_to_backend({
      notes,
      trainingGroup: selectedTrainingGroups.join(', '),
      title,
      logVideoOnLength,
      dbIndex,
      video_description: data.snippet.description,
      training_date: trainingDate.toISOString().split('T')[0],
    });
    toggleModal();
  };

  const onChangeDate = (event, selectedDate) => {
    if (selectedDate) {
      setTrainingDate(selectedDate);
    }
  };

  const onSelectGroup = (newSelected) => {
    setSelectedTrainingGroups(newSelected);
  };

  const deleteDataFromServer = async () => {
    setIsLoading(true);
    // TODO: Upload to your server
    const payload = {
      dbIndex,
    };

    const response = await deleteVideoLogFromServer.request(payload);
    console.log('Response: ', response.data);
    setIsLoading(false);

    // Check if setRefreshNeeded is passed from route.params
    if (route.params?.setRefreshNeeded) {
      // Call setRefreshNeeded to refresh the previous page
      route.params.setRefreshNeeded(true);
    }

    // Navigate back to the previous page
    navigation.goBack();
  };

  return (
    <DismissKeyboard>
      <ScrollView>
        <KeyboardAvoidingView>
          <Modal isVisible={isModalVisible}>
            <View style={styles.modalContent}>
              <Text>Title</Text>
              <TextInput style={styles.textInput} value={title} onChangeText={setTitle} />
              <Text>Training Group</Text>
              {/* <MultiSelectComponent data={dataTraining} selected={selectedTrainingGroups} setSelected={setSelectedTrainingGroups} /> */}
              <TrainingGroupSelector groupData={settings.training_groups} selected={selectedTrainingGroups} onSelect={onSelectGroup} />
              <Text>Notes</Text>
              <TextInput style={styles.textInput} value={notes} onChangeText={setNotes} />
              <Text>Date of Training:</Text>
              <DateTimePicker testID="dateTimePicker" value={trainingDate} mode="date" display="default" onChange={onChangeDate} />
              <Switch
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={isActualTime ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={() => setIsActualTime(!isActualTime)}
                value={isActualTime}
              />
              <Text>{isActualTime ? 'Logging on actual time watched' : 'Logging on video length'}</Text>
              <Text></Text>
              <Button title="Save Log" onPress={onSaveLog} />
              <Button title="Cancel" onPress={toggleModal} />
            </View>
          </Modal>
          <YoutubePlayer height={playerHeight} videoId={route.params.id} onChangeState={onStateChange} />
          <View style={styles.container}>
            {!route.params.id ? (
              <Text style={center}></Text>
            ) : isFetching ? (
              <ActivityIndicator size="large" color={PRIMARY_COLOR} />
            ) : error ? (
              <Text style={styles.center}>{error.message}</Text>
            ) : !data ? (
              <Text style={styles.center}>No data found for video id: {route.params.id}</Text>
            ) : (
              <>
                <View style={styles.textContainer}>
                  <Text style={styles.titleText}>
                    {decode(data.snippet.title)}const [showDescription, setShowDescription] = React.useState(false);
                  </Text>
                  <Text style={styles.subText}>
                    {decode(data.snippet.channelTitle)} • {formatTimeAgo(data.snippet.publishedAt)}
                  </Text>

                  {showDescription && <Text style={styles.description}>{data.snippet.description}</Text>}
                  <Text style={styles.timerText}>Time Watched: {accumulatedTime} seconds</Text>
                  <Button title={showDescription ? 'Hide Description' : 'Show Description'} onPress={() => setShowDescription(!showDescription)} />
                </View>
                <View style={styles.spacer} />
                <Button title="Save Log" onPress={toggleModal} />
                {route.params?.log && <Button title="Delete Log" onPress={() => deleteDataFromServer()} />}
                {/*
                <Text>Video Response JSON:</Text>
                <Text>{JSON.stringify(data, null, 4)}</Text>
                */}
              </>
            )}
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  spacer: {
    height: 30,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 22,
    justifyContent: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
  },

  textContainer: { flexDirection: 'column' },
  titleText: { marginBottom: 5, fontWeight: 'bold' },
  subText: { fontSize: 12, color: 'gray' },

  description: { fontSize: 12, marginTop: 10 },
});
