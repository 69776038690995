import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import useShareIntent from './hooks/useShareIntent';

//from guide at https://github.com/achorein/expo-share-intent-demo
function ShareListener() {
  const navigation = useNavigation();
  const { shareIntent, resetShareIntent } = useShareIntent();

  if (!!shareIntent) {
    //console.log(shareIntent);
    navigation.navigate('UploadFile', { sharedFile: shareIntent });
  }
}

export default ShareListener;
