import React from 'react';
import { Linking, StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { BLUE_COLOR, PRIMARY_COLOR } from '../../constants';
import useAuth from '../../auth/useAuth';
import { FontAwesome } from '@expo/vector-icons';
import { useSettings } from '../../components/SettingsContext';
import Button from '../../components/Button';

export function Overview() {
  const auth = useAuth();
  const navigation = useNavigation();
  const { settings, loadSettings } = useSettings();

  if (settings?.license === 'false') {
    return (
      <View style={styles.container}>
        <Text></Text>
        <Text></Text>
        <Text></Text>
        <Text style={{ fontWeight: 'bold', alignSelf: 'center' }}>No Active Service</Text>
        <Text>This User Account does not have active service. Please use CertifyMeUp website to manage your account.</Text>
        <Button title="Retry" onPress={() => loadSettings()} />
        <Button title="Log Out" onPress={() => auth.logOut()} />
      </View>
    );
  }

  return (
    <View style={styles.fullContainer}>
      <Image style={styles.logo} source={require('../../../assets/KeepConnectlogoblankback.png')} />
      <View style={styles.container}>
        <View style={styles.iconRow}>
          <TouchableOpacity style={styles.iconBox} onPress={() => navigation.navigate('Certifications')}>
            <FontAwesome name="certificate" size={24} color="black" />
            <Text>Certs</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => navigation.navigate('Search')}>
            <FontAwesome name="youtube-play" size={24} color="black" />
            <Text>CPC On{'\n'}YouTube</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => navigation.navigate('UploadFile')}>
            <FontAwesome name="upload" size={24} color="black" />
            <Text>Log CPC</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.iconRow}>
          <TouchableOpacity style={styles.iconBox} onPress={() => navigation.navigate('LogView')}>
            <FontAwesome name="file-text" size={24} color="black" />
            <Text>CPC{'\n'}Logs</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => navigation.navigate('Settings')}>
            <FontAwesome name="gear" size={24} color="black" />
            <Text>Settings</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => auth.logOut()}>
            <FontAwesome name="sign-out" size={24} color="black" />
            <Text>Logout</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerRow}>
          <View style={styles.footerCol}>
            <Text style={styles.TextStyle} onPress={() => Linking.openURL('mailto:support@certifymeup.com')}>
              Support
            </Text>
          </View>
          <View style={styles.footerCol}></View>
          <View style={styles.footerCol}>
            <Text style={styles.TextStyle} onPress={() => Linking.openURL('https://certifymeup.com/privacy-policy/')}>
              Privacy
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  fullContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    width: '100%',
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 20,
    alignSelf: 'center',
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 10,
  },
  iconBox: {
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: PRIMARY_COLOR,
    marginBottom: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  footer: {
    width: '100%',
    flexDirection: 'row', // added flexDirection
    alignItems: 'center', // added alignItems
    backgroundColor: BLUE_COLOR,
    paddingBottom: 10,
    paddingTop: 10, // added paddingTop for some space at the top
  },
  footerRow: {
    flex: 1, // added flex
    flexDirection: 'row',
    justifyContent: 'center', // added justifyContent
  },
  footerCol: {
    flex: 1,
  },
  TextStyle: {
    color: '#ffffff',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});
