import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

export function ResultCardFileUpload({
  id,
  logType,
  title,
  trainingGroup,
  hours,
  filename,
  log,
  setRefreshNeeded,
}) {
  const navigation = useNavigation();

  function handleResultClick() {
    // Navigate to appropriate details screen (if necessary)
    navigation.navigate('UploadFile', { id, title, log, setRefreshNeeded });
  }

  return (
    <TouchableOpacity style={styles.container} onPress={handleResultClick}>
      <View style={styles.textContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={styles.subText}>
          Training Group: {trainingGroup} • Type: {logType}
        </Text>
        <Text style={styles.subText}>
          Hours: {hours} hrs {'\n'}Filename: {filename}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    color: 'black',
    marginVertical: 5,
    shadowColor: 'black',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    borderWidth: 2,  // Add this line
    borderColor: "#ffff08",  // Add this line
  },
  textContainer: {
    flexDirection: 'column',
    padding: 10,
  },
  titleText: {
    fontSize: 16,
    marginBottom: 5,
  },
  subText: {
    fontSize: 12,
    color: 'gray',
  },
});
