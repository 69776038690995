import React from "react";
import { StyleSheet, Text } from "react-native";

//import Text from "../Text";

function ErrorMessage2({ error, visible }) {
  if (!visible || !error) return null;

  return <><Text style={styles.error}>{error}</Text></>;
}

const styles = StyleSheet.create({
  error: { color: "red", alignSelf: "center" },
});

export default ErrorMessage2;
