import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import {
  PRIMARY_COLOR,
  BLUE_COLOR,
  US_STATES,
  PMIData,
  PEStateData,
} from '../constants';

const ProgressBarsComponent = ({ data }) => {
  // Check if data is available and is an object
  if (!data || typeof data !== 'object' || !data.ledger) {
    return (
      <View style={styles.itemContainer}>
        <Text>No CPC Hours Associated</Text>
      </View>
    );
    //return <Text>Loading or no data available</Text>;
  }

  let LookUpTable; // Declare the variable outside the switch statement

  switch (data.certification_type) {
    case 'PE':
      LookUpTable = PEStateData;
      break;
    case 'PMI':
      LookUpTable = PMIData;
      break;
    default:
      // Handle other cases or set a default value for LookUpTable
      break;
  }

  let ledgerData = {};
  try {
    ledgerData = JSON.parse(data.ledger);
  } catch (error) {
    console.error('Error parsing ledger data:', error);
    return <Text>Error loading ledger data</Text>;
  }

  const requiredCategories = LookUpTable[data.certLevel2]?.categories || {};

  // Calculate the sum of all hours from the ledger
  const sumOfAllTrainingHours = ledgerData.SumTotal?.total
    ? ledgerData.SumTotal?.total
    : Object.values(ledgerData).reduce((acc, val) => acc + val.total, 0);

  return (
    <View style={styles.container}>
      <View style={styles.itemContainer}>
        {/* Progress Bar for Sum of All Training Hours */}
        <Text>
          Total Hours {sumOfAllTrainingHours} (Required:{' '}
          {LookUpTable[data.certLevel2]?.PDH || 'N/A'})
        </Text>
        <ProgressBar
          progress={
            sumOfAllTrainingHours / (LookUpTable[data.certLevel2]?.PDH || 1)
          }
          width={200}
          color={BLUE_COLOR}
        />
        <View style={styles.divider} />

        {/* Progress Bars for Training Groups */}
        {Object.keys(requiredCategories).length > 0 ? (
          Object.keys(requiredCategories).map((category, index) => {
            const group = ledgerData[category] || { total: 0 };
            // Calculate the denominator for the progress bar
            let denominator =
              requiredCategories[category]?.min ||
              requiredCategories[category]?.max;
            // if (denominator === undefined) {
            //   denominator = requiredCategories[category];
            // }

            // Ensure the denominator is not zero to avoid division by zero

            denominator = denominator || 1;
            return (
              <>
                {/* <View style={styles.divider} /> */}
                <View key={index}>
                  {/* {console.log("Categories", requiredCategories[category])} */}
                  <Text>
                    {category}
                    {': ' + (ledgerData[category]?.total || 0)} (
                    {requiredCategories[category]?.required && 'Required: '}
                    {requiredCategories[category]?.min &&
                      requiredCategories[category]?.min}
                    {requiredCategories[category]?.max &&
                      ' Max: ' + requiredCategories[category]?.max}
                    )
                  </Text>
                  <ProgressBar
                    progress={(group?.total || 0) / denominator}
                    width={200}
                    color={BLUE_COLOR}
                  />
                </View>
              </>
            );
          })
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  itemContainer: {
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 4,
    padding: 8,
    marginBottom: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  divider: {
    height: 1,
    backgroundColor: 'grey',
    marginVertical: 8,
  },
});

export default ProgressBarsComponent;
