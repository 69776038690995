import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, Platform, ScrollView, TextInput, TouchableOpacity, Modal } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import DateTimePicker from '../../components/DateTimePickerWrapper';
import { PRIMARY_COLOR, BLUE_COLOR, PEStateData, PMIData } from '../../constants';
import useApi from '../../hooks/useApi';
import keepConnectApi from '../../api/keepConnectsApi';
import Button from '../../components/Button';
import Dropdown1 from '../../components/Dropdown';
import { useSettings } from '../../components/SettingsContext';
import ProgressBarsComponent from '../../components/ProgressBarsComponent';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import base64 from 'react-native-base64';
import { FontAwesome } from '@expo/vector-icons';
import alert from '../../components/alert';

export function ViewLedger({ route, newState }) {
  //const route = useRoute();
  const sendCertificationToServer = useApi(keepConnectApi.sendCertificationToServer);
  const deleteCertificationFromServer = useApi(keepConnectApi.deleteCertificationFromServer);
  const getLedgerLogs = useApi(keepConnectApi.getLogsForReconciliation);
  const getLedgers = useApi(keepConnectApi.getHistoricLedgers);
  const getLedgersAndLogs = useApi(keepConnectApi.getLedgerAndLogs);
  const getLogsAndLedgerExport = useApi(keepConnectApi.getLogsAndLedgerExport);
  const getLogsForReconciliation = useApi(keepConnectApi.getLogsForReconciliation);
  const addRecordLedger = useApi(keepConnectApi.addRecordLedger);
  const deleteRecordLedger = useApi(keepConnectApi.deleteRecordLedger);
  const { settings, addTrainingGroup, removeTrainingGroup } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [certificationType, setCertificationType] = useState('PE');
  const [certLevel2, setCertLevel2] = useState(route.params?.log?.certLevel2 || newState);
  const [renewalDate, setRenewalDate] = useState(new Date());
  const [continuingHours, setContinuingHours] = useState('');
  const [additionalData, setAdditionalData] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [dbIndex, setDbIndex] = useState(null);
  const [previousRenewalDate, setPreviousRenewalDate] = useState(new Date());
  const [initialLicenseDate, setInitialLicenseDate] = useState(new Date());
  const [previousRenewalRecords, setPreviousRenewalRecords] = useState([]);
  const [logs, setLogs] = useState(null);
  const [ledger, setLedger] = useState({});
  const [historicLedgers, setHistoricLedgers] = useState([]);
  const [sortedLogs, setSortedLogs] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
  const [isGroupModalVisible, setGroupModalVisible] = useState(false);
  const [isIntervalModalVisible, setIntervalModalVisible] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState();
  const [selectedLog, setSelectedLog] = useState(null);
  const [splitHours, setSplitHours] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});

  let LookUpTable; // Declare the variable outside the switch statement

  switch (certificationType) {
    case 'PE':
      LookUpTable = PEStateData;
      break;
    case 'PMI':
      LookUpTable = PMIData;
      break;
    default:
      // Handle other cases or set a default value for LookUpTable
      break;
  }

  const showCategoryModal = (log) => {
    if (route.params?.previousLedgers) {
      if (log.log_type === 'video') {
        navigation.navigate('Video', {
          id: log.id,
          title: log.title,
          log,
          setRefreshNeeded: route.params.setRefreshNeeded,
        });
      } else {
        navigation.navigate('UploadFile', {
          id: log.id,
          title: log.title,
          log,
          setRefreshNeeded: route.params.setRefreshNeeded,
        });
      }
    } else {
      setSelectedLog(log);
      setGroupModalVisible(true);
    }
  };

  const toggleCategory = (category) => {
    setExpandedCategories((prevState) => {
      // If the category is already expanded, delete it from the object
      if (prevState[category]) {
        const newState = { ...prevState };
        delete newState[category];
        return newState;
      } else {
        // Otherwise, add the category to the object
        return { ...prevState, [category]: true };
      }
    });
  };

  useEffect(() => {
    const { log } = route.params || {};
    if (log) {
      console.log('Log: ', log);
      setCertificationType(log.certification_type || '');
      setCertLevel2(log.certLevel2 || newState || '');
      setRenewalDate(new Date(log.renewal_date) || new Date());
      setInitialLicenseDate(new Date(log.initial_date) || new Date());
      setContinuingHours(log.continuing_hours || '');
      setLicenseNumber(log.license_number || '');
      setDbIndex(log.id || null);
      setPreviousRenewalDate(new Date(log.previous_renewal_date) || new Date());
      setAdditionalData(log.additional_data || '');
      let updatedLedger = { ...JSON.parse(log.ledger) };
      const categories = LookUpTable[certLevel2].categories;

      // Add any missing categories with 0 total
      Object.keys(categories).forEach((category) => {
        if (!updatedLedger[category]) {
          updatedLedger[category] = { total: 0, ids: [] };
        }
      });

      if (!updatedLedger.CarryOver) {
        updatedLedger.CarryOver = { total: 0, ids: [] };
      }
      if (!updatedLedger.Unreconciled) {
        updatedLedger.Unreconciled = { total: 0, ids: [] };
      }

      setLedger(updatedLedger);
    }
    if (route.params?.previousLedgers) {
      loadLedgers(log.id);
    } else {
      reconcilePDH();
    }
  }, []);

  useEffect(() => {
    if (!logs || !ledger) return;
    processLedger({ ...ledger });
  }, [logs]);

  const processLedger = (ledgerCopy) => {
    const categoryProperties = LookUpTable[certLevel2].categories;
    let sortedLogsTemp = {};

    // Initialize all categories in sortedLogsTemp, even if they are empty.
    Object.keys(ledger).forEach((category) => {
      if (category !== 'SumTotal') {
        sortedLogsTemp[category] = [];
        ledgerCopy[category].total = 0;
      }
    });

    let unreconciledTotal = 0; // Initialize a variable to keep track of the total sum for "Unreconciled"

    logs.forEach((log) => {
      let foundCategory = null;

      // Check if this log's id is already recorded in any ledger category
      //console.log('Ledger Copy', ledgerCopy);
      //console.log('Ledger Copy', JSON.stringify(ledgerCopy, null, 2));
      for (const [category, { ids }] of Object.entries(ledgerCopy)) {
        if (category === 'SumTotal') continue;
        ids.forEach((item) => {
          if (item.id === log.id) {
            foundCategory = category;
            // If the item has a child object, create a new log
            let logHours = 0;
            if (item.child) {
              const newLog = { ...log, ...item.child };
              sortedLogsTemp[category].push(newLog);
              logHours = item.child.hours;
            } else {
              if (item.modifiedHours) log.hours = item.modifiedHours; // adjust the the hours of the LOG if the Ledger shows it as modified (ie, a split was done on it)
              sortedLogsTemp[category].push(log);
              logHours = log.hours;
            }
            // Update the category total based on logHours
            ledgerCopy[foundCategory].total = parseInt(ledgerCopy[foundCategory].total, 10) + parseInt(logHours, 10);

            // If rollup is enabled, walk the parent path and sum on the parent

            if (categoryProperties[foundCategory]?.rollup) {
              const parentPath = categoryProperties[foundCategory]?.parentPath?.split('/'); //parentPath includes this entry too
              if (parentPath) {
                for (let parent of parentPath) {
                  if (parent && categoryProperties.hasOwnProperty(parent)) {
                    let max = categoryProperties[parent].max;

                    // Do not roll up to the parent if max is hit
                    if (max !== undefined && results[parent] >= max) {
                      break;
                    }

                    // Roll up to the parent
                    ledgerCopy[parent].total = parseInt(ledgerCopy[parent].total, 10) + parseInt(logHours, 10);
                  }
                }
              }
            }
          }
        });
        // if (foundCategory) {
        //   break;
        // }
      }

      // If not found in any category, put it into "Unreconciled" category
      if (!foundCategory) {
        sortedLogsTemp['Unreconciled'].push(log);
        unreconciledTotal = parseInt(unreconciledTotal, 10) + parseInt(log.hours, 10); // Assuming each log has an 'hours' field
      }
    });

    ledgerCopy.SumTotal = { total: 0 };

    let sumTotal = 0;
    Object.keys(ledgerCopy).forEach((category) => {
      if (!categoryProperties[category]?.parentPath) {
        sumTotal += ledgerCopy[category].total;
      }
    });

    ledgerCopy.SumTotal.total = sumTotal;
    // Add the total sum for "Unreconciled" logs to sortedLogsTemp
    sortedLogsTemp['Unreconciled'].total = parseInt(unreconciledTotal, 10);

    //console.log("Processed Ledger: ", ledgerCopy);
    setSortedLogs(sortedLogsTemp);
    setLedger(ledgerCopy);
    //console.log("Sorted Logs: ", sortedLogsTemp);
  };

  const navigation = useNavigation();

  const collectIds = (obj, startKey) => {
    let ids = [];

    const helper = (subObj) => {
      if (subObj && subObj.id) {
        ids.push(subObj.id);
      }
      for (const key in subObj) {
        if (typeof subObj[key] === 'object' && key !== 'owner') {
          helper(subObj[key]);
        }
      }
    };

    helper(obj[startKey]);

    return ids.join(',');
  };

  const selectInterval = (selected) => {
    setSelectedInterval(selected);
    setIsLoading(true);
    loadHistoricLedgerAndLogs(selected);
    //setIntervalModalVisible(false);
  };

  const getTrainingGroupNames = (idsString) => {
    const desiredIds = idsString.split(',').map((id) => id.trim());
    const groupNames = [];

    // Fetching certLevel2Groups
    const certLevel2Groups = settings.training_groups[certificationType][certLevel2];

    if (certLevel2Groups) {
      for (const [name, details] of Object.entries(certLevel2Groups)) {
        // Check if the current property is an object and not one of the string properties
        if (typeof details === 'object' && details.id && desiredIds.includes(details.id) && details.disabled === '0') {
          groupNames.push(name);
        }
      }
    }
    return groupNames;
  };

  const clearLedger = () => {
    let updatedLedger = {};
    const categories = LookUpTable[certLevel2].categories;

    //console.log('imported renewal date: ', new Date(log.renewal_date + 'T00:00:00'));

    // Add any missing categories with 0 total
    Object.keys(categories).forEach((category) => {
      if (!updatedLedger[category]) {
        updatedLedger[category] = { total: 0, ids: [] };
      }
    });

    if (!updatedLedger.CarryOver) {
      updatedLedger.CarryOver = { total: 0, ids: [] };
    }
    if (!updatedLedger.Unreconciled) {
      updatedLedger.Unreconciled = { total: 0, ids: [] };
    }
    //console.log('Update Ledger:', JSON.stringify(updatedLedger));

    setLedger(updatedLedger);
    if (route.params?.setLedger) route.params.setLedger(updatedLedger);
  };

  const saveLedger = async () => {
    setIsLoading(true);
    if (route.params?.setLedger) {
      route.params.setLedger(ledger);
    }
    if (route.params?.previousLedgers) {
      const response = await addRecordLedger.request({
        certification_id: dbIndex,
        periodEndDate: selectedInterval,
        ledger: JSON.stringify(ledger),
      });
    }
    navigation.goBack();
  };

  const deleteLedger = async () => {
    setIsLoading(true);
    if (route.params?.setLedger) {
      clearLedger(); // Clear the ledger on the previous screen
    }
    if (route.params?.previousLedgers) {
      const response = await deleteRecordLedger.request({
        certification_id: dbIndex,
        periodEndDate: selectedInterval,
      });
    }
    //setIsLoading(false);
    if (route.params?.setRefreshNeeded) route.params?.setRefreshNeeded(true);
    navigation.goBack();
  };

  const reconcilePDH = async () => {
    const response = await getLogsForReconciliation.request({
      certification_id: route.params?.log?.id,
    });
    // Assume that response.data returns an object {logs: [], ledger: {}}
    console.log('Reconcile Pull:', JSON.stringify(response.data[0]));
    setLogs(response.data);
    setIsLoading(false);
    setCategoryModalVisible(true);
  };

  const loadHistoricLedgerAndLogs = async (selected) => {
    const response = await getLedgersAndLogs.request({
      certification_id: dbIndex,
      periodEndDate: selected,
    });
    // Assume that response.data returns an object {logs: [], ledger: {}}
    console.log('Ledger and Logs Pull:', response.data);
    setLedger(JSON.parse(response.data.ledger[0].ledger));
    setLogs(response.data.logs);
    setIsLoading(false);
    setIntervalModalVisible(false);
  };

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return base64.encode(binary);
  }

  const downloadBlobForRNW = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const downloadLedger = async () => {
    setIsLoading(true);
    const timestamp = new Date().getTime(); // Current time as a timestamp
    const localUri = `${FileSystem.documentDirectory}ledger_logs_and_files_${timestamp}.zip`;

    const response = await getLogsAndLedgerExport.request({
      certification_id: dbIndex,
      periodEndDate: selectedInterval,
    });
    // console.log("Response: ", response);
    if (response.ok) {
      //const blob = new Blob([response.data], { type: 'application/zip' });

      console.log('Response:', response.data);
      console.log('Type of response.data:', typeof response.data);

      if (Platform.OS === 'web') {
        // Web-specific download
        const blob = new Blob([response.data], { type: 'application/zip' });
        downloadBlobForRNW(blob, `ledger_logs_and_files_${timestamp}.zip`);
        setIsLoading(false);
        setIntervalModalVisible(false);
      } else {
        // Use FileSystem.writeAsStringAsync to save the file locally
        const base64Data = arrayBufferToBase64(response.data);
        console.log('Base64 Data: ', base64Data);

        await FileSystem.writeAsStringAsync(localUri, base64Data, {
          encoding: FileSystem.EncodingType.Base64,
        });

        console.log('Made it here2');

        setIsLoading(false);
        setIntervalModalVisible(false);

        // Check if sharing is available
        if (!(await Sharing.isAvailableAsync())) {
          alert(`Uh oh, sharing isn't available on your platform`);
          return;
        }

        // Prompt user to share or save the downloaded file
        await Sharing.shareAsync(localUri);

        // Delete the file after a delay (e.g., 10 seconds)
        setTimeout(() => {
          FileSystem.deleteAsync(localUri);
        }, 10000);
      }
    } else {
      // Handle error case
      alert('Failed to download the ledger and logs.');
      setIsLoading(false);
      setIntervalModalVisible(false);
    }
  };

  const loadLedgers = async (dbIndex) => {
    setIsLoading(true);
    const response = await getLedgers.request({ certification_id: dbIndex });
    // Assume that response.data returns an object {logs: [], ledger: {}}
    console.log('Historic Ledgers Pull:', response.data);
    if (response.data.includes('No ledger found')) {
      alert('No historic ledgers found.', 'Click OK to return to previous screen', [
        {
          text: 'OK',
          onPress: () => {
            navigation.goBack();
          },
        },
      ]);
      return;
    }
    setHistoricLedgers(response.data);

    setIsLoading(false);
    setIntervalModalVisible(true);
  };

  const onChangeItemCategory = (newCategory) => {
    if (selectedLog.id === null) return;

    let updatedLedger = { ...ledger };

    // Search for and remove the log (or its child) from its current category
    const categoryKeys = Object.keys(updatedLedger);
    for (let category of categoryKeys) {
      if (category === 'SumTotal') continue;
      const logIndex = updatedLedger[category].ids.findIndex((log) =>
        selectedLog.childID ? log.child?.childID === selectedLog.childID : log.id === selectedLog.id,
      );
      if (logIndex > -1) {
        const removedLog = updatedLedger[category].ids.splice(logIndex, 1)[0];
        //updatedLedger[category].total = parseInt(updatedLedger[category].total, 10) - parseInt(removedLog.hours, 10);
        break;
      }
    }

    // Add the log to the new category
    if (!updatedLedger[newCategory]) {
      updatedLedger[newCategory] = { total: 0, ids: [] };
    }
    if (selectedLog.childID) {
      // Create the child object
      const childObject = {
        childID: selectedLog.childID,
        parentID: selectedLog.parentID || selectedLog.id,
        hours: selectedLog.hours,
        childType: selectedLog.childType,
      };
      updatedLedger[newCategory].ids.push({ id: selectedLog.id, child: childObject });
    } else {
      updatedLedger[newCategory].ids.push({ id: selectedLog.id });
    }
    //updatedLedger[newCategory].total = parseInt(updatedLedger[newCategory].total, 10) + parseInt(selectedLog.hours, 10);

    // Update the ledger
    //setLedger(updatedLedger);
    processLedger(updatedLedger);
    setGroupModalVisible(false);
  };

  const onSplitOrDuplicateLog = (actionType) => {
    // Create a deep copy of the existing ledger
    let updatedLedger = JSON.parse(JSON.stringify(ledger));

    // Validate that splitHours is within bounds for a 'split' operation
    if (actionType === 'split' && (splitHours <= 0 || splitHours >= selectedLog.hours)) {
      console.error('Invalid number of hours for splitting.');
      return;
    }

    // Find the category where the log currently resides
    let foundCategory = null;
    let foundIndex = -1;
    for (const [category, { ids }] of Object.entries(updatedLedger)) {
      if (!ids) continue; // this should guard against if the ledger is empty or trying to split/dup records before reconciling
      const logIndex = ids.findIndex((item) => (selectedLog.childID ? item.child?.childID === selectedLog.childID : item.id === selectedLog.id));
      if (logIndex > -1) {
        foundCategory = category;
        foundIndex = logIndex;
        break;
      }
    }

    if (!foundCategory) {
      // Log not found in any category, maybe handle this case differently.
      console.error('Log not found in any category.');
      return;
    }

    // Generate new childID
    const newChildID = `${selectedLog.childID ? selectedLog.childID : selectedLog.id}_${Date.now()}`;

    // Create the child object
    const childObject = {
      childID: newChildID,
      parentID: selectedLog.childID || selectedLog.id,
      hours: actionType === 'split' ? splitHours : selectedLog.hours,
      childType: actionType,
    };

    // Add this as a child log in the appropriate category
    updatedLedger[foundCategory].ids.push({
      id: selectedLog.id,
      child: childObject,
    });

    if (actionType === 'split') {
      // Update the original log's hours
      if (selectedLog.childID) {
        updatedLedger[foundCategory].ids[foundIndex].child.hours =
          parseInt(updatedLedger[foundCategory].ids[foundIndex].child.hours, 10) - parseInt(splitHours, 10);
      } else {
        if (updatedLedger[foundCategory].ids[foundIndex].modifiedHours) {
          updatedLedger[foundCategory].ids[foundIndex].modifiedHours =
            parseInt(updatedLedger[foundCategory].ids[foundIndex].modifiedHours, 10) - parseInt(splitHours, 10);
        } else {
          const targetLog = logs.find((log) => log.id === selectedLog.id);
          if (targetLog) updatedLedger[foundCategory].ids[foundIndex].modifiedHours = parseInt(targetLog.hours, 10) - parseInt(splitHours, 10);
        }
      }
    } else if (actionType === 'duplicate') {
      // Update the total hours for the category
      //updatedLedger[foundCategory].total = parseInt(updatedLedger[foundCategory].total, 10) + parseInt(childObject.hours, 10);
    }

    // Update the ledger state
    processLedger(updatedLedger);
    setGroupModalVisible(false);
  };

  return (
    <View style={styles.container}>
      {/* Nested Group Selection Modal */}
      <View>
        <Modal animationType="fade" transparent={true} visible={isGroupModalVisible}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* <Text>Select new group for {selectedLog?.title}</Text> */}
              <View style={styles.splitBox}>
                <Dropdown1
                  labelText="Move To New Group"
                  itemsArray={Object.keys(ledger).map((category) => {
                    return { label: category, value: category };
                  })}
                  onChangeItemProp={onChangeItemCategory}
                />
              </View>
              <View style={styles.splitBox}>
                <TextInput
                  style={styles.input}
                  placeholder="Hours to split"
                  onChangeText={(text) => setSplitHours(parseInt(text, 10))}
                  keyboardType="numeric"
                />
                <Button title="Split Hours Out" onPress={() => onSplitOrDuplicateLog('split')} />
              </View>
              <Button title="Duplicate Entry" onPress={() => onSplitOrDuplicateLog('duplicate')} />
              <Button title="Close" onPress={() => setGroupModalVisible(false)} />
            </View>
          </View>
        </Modal>

        <Modal animationType="fade" transparent={true} visible={isIntervalModalVisible}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {/* <Text>Select new group for {selectedLog?.title}</Text> */}
              <Dropdown1
                labelText="Select Ledger"
                defaultValueProp={selectedInterval}
                itemsArray={historicLedgers.map((item) => {
                  return {
                    label: item.periodEndDate,
                    value: item.periodEndDate,
                  };
                })}
                onChangeItemProp={selectInterval}
              />
              {/* <Button title="Select" onPress={() => selectInterval()} /> */}
            </View>
          </View>
        </Modal>
        {/* End of Nested Group Selection Modal */}
      </View>
      {isLoading ? (
        <ActivityIndicator size="large" color={PRIMARY_COLOR} />
      ) : (
        <>
          <View style={styles.titleBox}>
            <Text>Professional Engineer (PE)</Text>
            <Text>State: {certLevel2}</Text>
          </View>
          <View style={styles.ledgerView}>
            {/* {console.log("Sorted Logs: ", sortedLogs)} */}
            <Text style={styles.logTitle}>
              Total Hours: {ledger['SumTotal']?.total} ({LookUpTable[certLevel2]?.PDH || 'N/A'} Required)
            </Text>
            <ScrollView style={styles.scrollViewStyle}>
              {sortedLogs &&
                Object.keys(sortedLogs).map((category, index) => (
                  <TouchableOpacity key={index} onPress={() => toggleCategory(category)}>
                    <View key={index} style={styles.categoryBox}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Text style={styles.logTitle}>
                          {category}:{category !== 'Unreconciled' && ledger?.[category]?.total}
                          {sortedLogs[category].total} Hours
                          {LookUpTable[certLevel2].categories.hasOwnProperty(category)
                            ? ' (Required: ' + LookUpTable[certLevel2].categories[category].min + ')'
                            : ''}
                        </Text>
                        <FontAwesome name={expandedCategories[category] ? 'minus' : 'plus'} size={24} color="black" />
                      </View>
                      {expandedCategories[category] &&
                        sortedLogs[category].map((log, i) => (
                          <TouchableOpacity key={i} onPress={() => showCategoryModal(log)} style={styles.logCard}>
                            <Text style={styles.logTitle}>{log.title}</Text>
                            <View style={styles.logDetails}>
                              <Text style={styles.logDetailItem}>Hours: {log.hours}</Text>
                              <Text style={styles.logDetailItem}>Date: {log.training_date}</Text>

                              {/* Get the tagged categories */}
                              {getTrainingGroupNames(log.training_group).map((name, index) => (
                                <Text key={index} style={styles.logDetailItem}>
                                  {name}
                                </Text>
                              ))}
                            </View>
                            {log.source === 'CarryForward' && <Text style={styles.logDetailItem}>Carried Forward</Text>}
                            <View style={styles.logDetails}></View>
                          </TouchableOpacity>
                        ))}
                    </View>
                  </TouchableOpacity>
                ))}
            </ScrollView>
          </View>
          <View style={styles.iconRow}>
            {/* Export Ledger Button */}
            <TouchableOpacity style={styles.iconBox} onPress={downloadLedger}>
              <FontAwesome name="download" size={24} color="black" />
              <Text>Export</Text>
            </TouchableOpacity>

            {/* Save Certification Button */}
            <TouchableOpacity style={styles.iconBox} onPress={saveLedger}>
              <FontAwesome name="save" size={24} color="black" />
              <Text>Save</Text>
            </TouchableOpacity>

            {/* Conditional Delete Certification Button */}
            {dbIndex && (
              <TouchableOpacity style={styles.iconBox} onPress={deleteLedger}>
                <FontAwesome name="trash-o" size={24} color="black" />
                <Text>Delete</Text>
              </TouchableOpacity>
            )}
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#F5F5F5',
  },
  input: {
    borderWidth: 1,
    borderColor: '#e0e0e0',
    width: '100%',
    padding: 15,
    marginVertical: 10,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
  },
  leftAlignedLabel: {
    alignSelf: 'stretch',
    paddingLeft: 5,
    marginBottom: 5,
    color: '#666',
    fontWeight: '600',
  },
  categoryBox: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#e0e0e0',
    padding: 15,
    backgroundColor: '#FFFFFF',
    marginBottom: 15,
  },
  splitBox: {
    borderWidth: 1,
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderColor: '#e0e0e0',
    padding: 15,
    backgroundColor: '#FFFFFF',
    marginBottom: 15,
  },
  titleBox: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: PRIMARY_COLOR,
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#FFFFFF',
    marginBottom: 15,
  },
  modalView: {
    width: '90%',
    backgroundColor: 'white',
    borderRadius: 15,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  logCard: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#e0e0e0',
    padding: 10,
    marginBottom: 10,
    backgroundColor: '#FFFFFF',
  },
  logTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 8,
  },
  logDetails: {
    flexDirection: 'row', // Align children horizontally.
    flexWrap: 'wrap', // Allow the items to wrap.
    alignItems: 'center', // Vertically align items in the middle (optional).
    justifyContent: 'flex-start', // Align items to the start of the parent container (optional).
    // ... (other styles if any)
  },
  logDetailItem: {
    borderWidth: 1,
    padding: 4,
    borderRadius: 10,
    fontSize: 14,
    alignSelf: 'flex-start',
    borderColor: BLUE_COLOR,
    //backgroundColor: BLUE_COLOR,
    overflow: 'hidden',
    margin: 1,
  },
  buttonArea: {
    height: 300, // Or whatever height you want
  },
  scrollViewStyle: {
    height: '70%',
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: 10,
  },
  iconBox: {
    width: 80, // or adjust to your desired width
    height: 80, // ensure this matches width to keep it square
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: PRIMARY_COLOR,
    marginBottom: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // This is for Android
  },
});

// Sample styles with blue/yellow colors 1
// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: 20,
//     backgroundColor: '#F5F5F5',
//   },
//   input: {
//     borderWidth: 1,
//     borderColor: BLUE_COLOR,  // Blue border for inputs
//     width: '100%',
//     padding: 15,
//     marginVertical: 10,
//     borderRadius: 8,
//     backgroundColor: '#FFFFFF',
//   },
//   leftAlignedLabel: {
//     alignSelf: 'stretch',
//     paddingLeft: 5,
//     marginBottom: 5,
//     color: BLUE_COLOR,  // Blue for labels
//     fontWeight: '600',
//   },
//   categoryBox: {
//     borderWidth: 2,
//     borderRadius: 10,
//     borderColor: BLUE_COLOR,  // Blue border for category boxes
//     padding: 15,
//     backgroundColor: '#FFFFFF',
//     marginBottom: 15,
//   },
//   modalView: {
//     width: '90%',
//     backgroundColor: 'white',
//     borderRadius: 15,
//     padding: 20,
//     alignItems: 'center',
//     shadowColor: '#000',
//     shadowOffset: { width: 0, height: 2 },
//     shadowOpacity: 0.25,
//     shadowRadius: 4,
//   },
//   centeredView: {
//     flex: 1,
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'rgba(0,0,0,0.5)',
//   },
//   logCard: {
//     borderWidth: 1,
//     borderRadius: 10,
//     borderColor: BLUE_COLOR,  // Blue border for log cards
//     padding: 10,
//     marginBottom: 10,
//     backgroundColor: '#FFFFFF',
//   },
//   logTitle: {
//     fontWeight: 'bold',
//     fontSize: 16,
//     marginBottom: 8,
//     color: BLUE_COLOR,  // Blue for log titles
//   },
//   logDetails: {
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//   },
//   logDetailItem: {
//     backgroundColor: '#EFEFEF',
//     padding: 5,
//     borderRadius: 5,
//     fontSize: 14,
//   },
// });
