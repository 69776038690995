import { useQuery } from '@tanstack/react-query';

import { MAX_RESULTS, YOUTUBE_API_KEY } from '../../../constants';

export function useGetSearchResults(term) {
  return useQuery({
    queryFn: () =>
      fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${term}&type=video&key=${YOUTUBE_API_KEY}&maxResults=${MAX_RESULTS}`,
      ).then((res) => res.json()),
    queryKey: ['getSearchResults', term],
    enabled: !!term && term !== '',
  });
}

// I've left this here as an alternative fetch hook
// incase you don't want to use react-query
// It's not used in the app, but it's here for reference
// You can replace the useGetSearchResults hook in src/screens/Search/useSearch.js
export function useAlternativeGetSearchResults(term) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!term || term === '') return;

    setIsFetching(true);

    fetch(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${term}&type=video&key=${YOUTUBE_API_KEY}&maxResults=${MAX_RESULTS}`,
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [term]);

  return { data, isFetching, error };
}
