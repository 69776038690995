import { formatDistanceToNow, parseISO } from 'date-fns';

/**
 * Formats the given ISO date string to a human-readable relative time string.
 *
 * @param {string} published - An ISO-formatted date string.
 * @returns {string} A human-readable relative time string.
 */
export function formatTimeAgo(published) {
  try {
    return formatDistanceToNow(parseISO(published), {
      addSuffix: true,
    }).replace(/\b(about|over) \b/g, '');
  } catch (error) {
    console.error('Failed to format date:', error);
    return published;
  }
}

/**
 * Converts a YouTube duration string (ISO 8601) to a total number of seconds.
 *
 * @param {string} duration - The YouTube video duration string in ISO 8601 format (e.g., "PT1H15M39S").
 * @returns {number} - The total duration of the video in seconds.
 */
export function formatYouTubeDuration(duration) {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  const hours = parseInt((match[1] || '0').replace('H', ''), 10) || 0;
  const minutes = parseInt((match[2] || '0').replace('M', ''), 10) || 0;
  const seconds = parseInt((match[3] || '0').replace('S', ''), 10) || 0;

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  return totalSeconds;
}
