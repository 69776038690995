import { useRef } from 'react';

import { formatYouTubeDuration } from '../../utils';
import { useGetVideo } from './api/useGetVideo';
import keepConnectApi from "../../api/keepConnectsApi";
import useApi from "../../hooks/useApi";


export function useVideo(id) {
  const { data, error, isFetching } = useGetVideo(id);
  const sendLogToServer = useApi(keepConnectApi.sendVideoLogToServer);
  
  const videoData = data?.items?.[0];
  
  // Using refs here instead of state because this isn't required for renders
  const accumulatedTimeRef = useRef(0);
  const intervalRef = useRef(null);

  async function send_to_backend(extraData) {
    
    console.log('Sending to backend (values are sent as seconds)', {
      timeWatched: accumulatedTimeRef.current,
      videoLength: formatYouTubeDuration(videoData.contentDetails.duration),
      videoId: id,
      thumbnail: videoData.snippet.thumbnails.default.url,
      ...extraData,
    });
    const response = await sendLogToServer.request({
      timeWatched: accumulatedTimeRef.current,
      videoLength: formatYouTubeDuration(videoData.contentDetails.duration),
      videoId: id,
      thumbnail: videoData.snippet.thumbnails.default.url,
      title: videoData.snippet.title,
      ...extraData,
    });
    console.log(response);
  }

  function startTimer() {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        accumulatedTimeRef.current += 1;
      }, 1000);
    }
  }

  function stopTimer() {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      accumulatedTimeRef.current += 1; // count the second we stopped on
      intervalRef.current = null;
    }
  }

  function resetTimer() {
    accumulatedTimeRef.current = 0;
  }

  async function onStateChange(state) {
    switch (state) {
      case 'unstarted':
        console.log('unstarted');
        break;
      case 'playing':
        console.log('playing');
        startTimer();
        break;
      case 'paused':
        console.log('paused: ', accumulatedTimeRef.current, " ", id);
        stopTimer();
        break;
      case 'buffering':
        console.log('buffering');
        stopTimer();
        break;
      case 'ended':
        console.log('ended');
        stopTimer();
        if (!videoData) {
          break;
        }
        /*
        send_to_backend({
          timeWatched: accumulatedTimeRef.current,
          videoLength: formatYouTubeDuration(videoData.contentDetails.duration),
          videoId: id,
        })
          .then(() => {
            resetTimer();
          })
          .catch((e) => {
            console.log('Error sending data to backend', e);
          });
          */
        break;
      default:
        console.log('default state change case hit (unknown) ->', state);
    }
  }

  return { data: videoData, error, isFetching, onStateChange, send_to_backend, accumulatedTime: accumulatedTimeRef.current };
}
