import React from 'react';
import { ActivityIndicator, Keyboard, KeyboardAvoidingView, ScrollView, StyleSheet, View, Text } from 'react-native';

import { PRIMARY_COLOR } from '../../constants';
import { formatTimeAgo } from '../../utils';
import { SearchBar } from './components/SearchBar/SearchBar';
import { ResultCard } from './components/ResultCard/ResultCard';
import { useSearch } from './useSearch';
import DismissKeyboard from '../../components/DismissKeyboard';
import Button from '../../components/Button';
import useAuth from '../../auth/useAuth';

export function Search() {
  const { error, isFetching, onSearch, results, searchedTerm } = useSearch();
  const auth = useAuth();

  return (
    // I use DismissKeyboard to make scrolling work when the keyboard is open
    <DismissKeyboard onPress={Keyboard.dismiss}>
      <ScrollView>
        <KeyboardAvoidingView>
          <View style={styles.container}>
            <SearchBar onSearch={onSearch} clearable />
            {searchedTerm === '' ? (
              <Text style={styles.center}>Please search for something using the input above</Text>
            ) : isFetching ? (
              <ActivityIndicator size="large" color={PRIMARY_COLOR} />
            ) : error ? (
              <Text style={styles.center}>{error.message}</Text>
            ) : results.length === 0 ? (
              <Text style={styles.center}>
                No results found for <Text style={styles.bold}>{searchedTerm}</Text>
              </Text>
            ) : (
              <>
                {results.map((result) => (
                  <ResultCard
                    key={result.etag}
                    id={result.id.videoId}
                    thumbnail={result.snippet.thumbnails.high.url}
                    title={result.snippet.title}
                    channelName={result.snippet.channelTitle}
                    timeAgo={formatTimeAgo(result.snippet.publishedAt)}
                  />
                ))}
                <View style={styles.spacer} />
                <Text>First Result JSON:</Text>
                <Text>{JSON.stringify(results[0], null, 4)}</Text>
              </>
            )}
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    </DismissKeyboard>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  spacer: {
    height: 30,
  },
});
